import React, {
  ChangeEvent,
  DragEvent,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import {
  MdArrowBack,
  MdArrowForward,
  MdCheck,
  MdDeleteOutline,
  MdDescription,
  MdEdit,
  MdErrorOutline,
  MdOutlineClose,
  MdOutlineFileUpload,
} from 'react-icons/md';
import { filesize } from 'filesize';
import { Buffer } from 'buffer';
import classNames from '../../../../helpers/classNames';
import TrayPlusIcon from './icons/TrayPlusIcon';
import { digitallySignContext } from '../../../../context/DigitallySignContext/DigitallySignContext';
import { DigitallySignSteps } from '../DigitallySignStepsManager';
import { DocumentPDF } from '../../types/types';
import fileToBase64 from '../../../../helpers/fileToBase64';

enum UploadDocumentErrorMessages {
  INVALID_FILE = 'El archivo adjunto no es un PDF.',
  FILE_SIZE_EXCEEDED = 'El archivo adjunto es demasiado grande.',
}

const TEN_MEGA_BYTES = 10 * 1024 * 1024;

export default function UploadDocumentStep() {
  const { setCurrentDigitallySignStep, setDocumentPDF, documentPDF } =
    useContext(digitallySignContext);
  const [isEditingFileName, setIsEditingFileName] = useState(false);
  const [isFileNameValid, setIsFileNameValid] = useState(false);
  const [hasAttachedFile, setHasAttachedFile] = useState(false);
  const [isDroppingFile, setIsDroppingFile] = useState(false);
  const [errorMessage, setErrorMessage] =
    useState<UploadDocumentErrorMessages | null>(null);
  const [attachedFile, setAttachedFile] = useState<File | null>(null);
  const [isValidFile, setIsValidFile] = useState(false);
  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const fileNameInputRef = useRef<HTMLInputElement | null>(null);

  useEffect(() => {
    if (documentPDF) {
      setHasAttachedFile(true);
      const fileData = Buffer.from(documentPDF.data, 'base64');
      setAttachedFile(
        new File([fileData], documentPDF.fileName, {
          type: 'application/pdf',
        }),
      );
    }
  }, []);

  const showEditFileNameForm = () => {
    setIsEditingFileName(true);
  };

  const hideEditFileNameForm = () => {
    setIsEditingFileName(false);
  };

  const handleConfirmEditFileName = () => {
    const { value: newFileName } = fileNameInputRef.current;
    setAttachedFile(
      (prevFile) => new File([prevFile], newFileName, { type: prevFile.type }),
    );
    hideEditFileNameForm();
  };

  const handleFileNameChange = ({ target }: ChangeEvent<HTMLInputElement>) => {
    setIsFileNameValid(target.value.length > 0);
  };

  const handlePrevious = () => {
    setCurrentDigitallySignStep(DigitallySignSteps.CHOOSE_DOCUMENT_SOURCE);
  };

  const handleNext = async () => {
    setCurrentDigitallySignStep(DigitallySignSteps.CHOOSE_RECIPIENTS);

    const base64File = await fileToBase64(attachedFile);
    setDocumentPDF({
      fileName: attachedFile.name,
      data: base64File,
    } as DocumentPDF);
  };

  const handleRemove = () => {
    setHasAttachedFile(false);
    setAttachedFile(null);

    fileInputRef.current.value = null;
  };

  const isValidFileExtension = (file: File) => file.type === 'application/pdf';
  const isFileSizeExceeded = (file: File) => file.size > TEN_MEGA_BYTES;

  const handleFileUpload = (file: File) => {
    setIsValidFile(true);
    setHasAttachedFile(true);
    if (!isValidFileExtension(file)) {
      setErrorMessage(UploadDocumentErrorMessages.INVALID_FILE);
      setIsValidFile(false);
      return;
    }
    if (isFileSizeExceeded(file)) {
      setErrorMessage(UploadDocumentErrorMessages.FILE_SIZE_EXCEEDED);
      setIsValidFile(false);
      return;
    }
    setAttachedFile(file);
    setIsEditingFileName(false);
  };

  const handleUploadDocumentChange = ({
    target,
  }: ChangeEvent<HTMLInputElement>) => {
    if (!target.files?.length) {
      setHasAttachedFile(false);
      return;
    }
    handleFileUpload(target.files[0]);
  };

  const handleDrop = (ev: DragEvent<HTMLLabelElement>) => {
    ev.preventDefault();
    setIsDroppingFile(false);
    if (!ev.dataTransfer.files.length) {
      return;
    }
    handleFileUpload(ev.dataTransfer.files[0]);
  };

  const handleDragOver = (ev: DragEvent<HTMLLabelElement>) => {
    ev.preventDefault();
    setIsDroppingFile(true);
  };

  const handleDragLeave = (ev: DragEvent<HTMLLabelElement>) => {
    ev.preventDefault();
    setIsDroppingFile(false);
  };

  const renderInputLabel = () => {
    if (isDroppingFile) {
      return (
        <label
          onDrop={handleDrop}
          onDragOver={handleDragOver}
          onDragLeave={handleDragLeave}
          htmlFor="upload_unsigned_document"
          className="flex flex-col gap-6 items-center justify-center h-56 border-dashed p-8 rounded-xl border-4 bg-blue-50 border-blue-800 text-blue-800 font-semibold"
        >
          <TrayPlusIcon className="size-14 text-blue-800" />
          ¡Suelta tu archivo para añadirlo!
        </label>
      );
    }

    return (
      <label
        onDrop={handleDrop}
        onDragOver={handleDragOver}
        onDragLeave={handleDragLeave}
        htmlFor="upload_unsigned_document"
        className="flex flex-col gap-6 items-center justify-center h-56 border-dashed p-8 rounded-xl hover:bg-zinc-50 active:bg-zinc-100 active:border-zinc-600 border-2 border-zinc-400 text-zinc-800"
      >
        <MdOutlineFileUpload className="size-14 text-zinc-400" />
        <article className="flex flex-col gap-2 items-center">
          <span>
            <span className="font-medium text-blue-800">Sube un archivo</span> o
            arrástralo y suéltalo aquí
          </span>
          <span className="font-light text-sm text-zinc-800">
            PDF hasta 10MB
          </span>
        </article>
      </label>
    );
  };

  const renderErrorMessage = () => {
    if (!errorMessage) {
      return null;
    }

    return (
      <article className="flex items-center gap-2 bg-red-50 text-red-800 border border-red-800 rounded-xl p-4 w-full">
        <MdErrorOutline className="size-5" />
        {errorMessage}
      </article>
    );
  };

  const renderFileDetails = () => {
    if (isEditingFileName) {
      return (
        <article className="flex flex-col gap-1">
          <label
            htmlFor="file-name"
            className="text-xs text-zinc-600 font-semibold"
          >
            Nombre del archivo
          </label>
          <section className="flex items-center justify-center gap-2 w-96">
            <input
              ref={fileNameInputRef}
              id="file-name"
              type="text"
              className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
              defaultValue={attachedFile.name}
              onChange={handleFileNameChange}
            />
            <button
              type="button"
              className="flex items-center justify-center p-2 rounded-lg text-zinc-800 hover:bg-zinc-100 active:bg-zinc-200"
              aria-label="Cancelar edición"
              title="Cancelar edición"
              onClick={hideEditFileNameForm}
            >
              <MdOutlineClose className="size-5" />
            </button>
            <button
              type="button"
              className="flex items-center justify-center p-2 rounded-lg text-blue-800 bg-blue-50 hover:bg-blue-100 active:bg-blue-200 disabled:opacity-50 disabled:cursor-not-allowed"
              aria-label="Confirmar edición"
              title="Confirmar edición"
              disabled={!isFileNameValid}
              onClick={handleConfirmEditFileName}
            >
              <MdCheck className="size-5" />
            </button>
          </section>
        </article>
      );
    }

    return (
      <article className="flex flex-col gap-1 text-zinc-800">
        <section className="flex items-center justify-center gap-2">
          <span className="font-semibold text-lg max-w-96 truncate">
            {attachedFile.name}
          </span>
          <button
            type="button"
            className="flex items-center justify-center text-zinc-400 p-2 rounded-lg hover:text-zinc-800 hover:bg-zinc-100 active:bg-zinc-200"
            aria-label="Editar nombre del archivo"
            title="Editar nombre del archivo"
            onClick={showEditFileNameForm}
          >
            <MdEdit className="size-5" />
          </button>
        </section>
        {Boolean(attachedFile.size) && (
          <span className="text-sm">{filesize(attachedFile.size)}</span>
        )}
      </article>
    );
  };

  return (
    <section className="flex flex-col gap-9 min-w-[700px]">
      <header className="flex flex-col gap-3">
        <h3 className="text-4xl font-bold text-zinc-700">Subir documento</h3>
      </header>
      <input
        ref={fileInputRef}
        className="hidden"
        id="upload_unsigned_document"
        accept="application/pdf"
        type="file"
        onChange={handleUploadDocumentChange}
      />
      {renderInputLabel()}
      {attachedFile && (
        <article className="flex items-center gap-11 justify-between ">
          <section className="flex items-center gap-6">
            <figure className="flex items-center justify-center size-20 bg-zinc-100 rounded-xl">
              <MdDescription className="size-12 text-zinc-700 opacity-20" />
            </figure>
            {renderFileDetails()}
          </section>
          {!isEditingFileName && (
            <button
              type="button"
              className="flex items-center justify-center text-red-800 px-3 py-2 bg-red-50 rounded-lg hover:bg-red-100 active:bg-red-200"
              aria-label="Eliminar archivo"
              title="Eliminar archivo"
              onClick={handleRemove}
            >
              <MdDeleteOutline className="size-5" />
            </button>
          )}
        </article>
      )}
      {hasAttachedFile && !isValidFile && renderErrorMessage()}
      <footer className="flex justify-between">
        <button
          type="button"
          onClick={handlePrevious}
          className="flex items-center gap-1.5 px-3 py-2 hover:bg-zinc-100 rounded-lg active:bg-gray-200"
        >
          <MdArrowBack className="size-5" />
          Atrás
        </button>
        <button
          type="button"
          onClick={handleNext}
          disabled={!hasAttachedFile}
          className={classNames(
            'flex items-center gap-1.5 px-3 py-2 bg-blue-800 rounded-lg text-white disabled:opacity-50 disabled:cursor-not-allowed',
            'hover:bg-blue-900 active:bg-blue-950',
          )}
        >
          Siguiente
          <MdArrowForward className="size-5" />
        </button>
      </footer>
    </section>
  );
}
