const modernPlateRegex = /^[0-9]{4}[BCDFGHJKLMNPRSTVWXYZ]{3}$/;
const oldPlateRegex = /^[A-Z]{1,2}[0-9]{4}[A-Z]{1,2}$/;
const ciclomotorPlateRegex = /^C\d{4}[A-Z]{3}$/;
const historicalPlateRegex = /^H\d{4}[A-Z]{3}$/;
const farmingPlateRegex = /^E\d{4}[A-Z]{3}$/;
const trailerPlateRegex = /^R\d{4}[A-Z]{3}$/;

export const validatePlate = (text: string) =>
  modernPlateRegex.test(text) ||
  oldPlateRegex.test(text) ||
  ciclomotorPlateRegex.test(text) ||
  historicalPlateRegex.test(text) ||
  farmingPlateRegex.test(text) ||
  trailerPlateRegex.test(text);
