import axios from 'axios';
import React, { Fragment, useEffect, useReducer, useState } from 'react';
import { DotsVerticalIcon } from '@heroicons/react/solid';
import { Menu, Transition } from '@headlessui/react';
import { useAuth0 } from '@auth0/auth0-react';
import { PlusIcon } from '@heroicons/react/outline';
import { Tooltip } from 'react-tooltip';
import { validate as validateUUID } from 'uuid';

import { partsDictionary, statusBadgeDictionary } from './helpers/dictionaries';
import { getTooltipMessage } from './helpers/helpers';
import { extraActionsDictionary } from './helpers/actions';
import { classNames } from '../../helpers';
import DeleteDigitalSignatureModal from '../../screens/Backoffice/transfers/components/DeleteDigitalSignatureModal';
import DigitalSignatureModal from '../../screens/Backoffice/transfers/components/DigitalSignatureModal';
import CustomSignatureModal from '../../screens/Backoffice/transfers/components/CustomSignatureModal';
import ModuleWrapper from '../../screens/Backoffice/transfers/components/ModuleWrapper';
import { ENTITY_TYPES } from '../../helpers/enums';

function SignatureDetails({
  tramitType,
  tramitCode,
  tramit,
  setContractSignatureDate,
}) {
  const [documents, setDocuments] = useState([]);
  const [documentIdForDelete, setDocumentIdForDelete] = useState('');

  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [openCreateModal, setOpenCreateModal] = useState(false);
  const [openCustomModal, setOpenCustomModal] = useState(false);

  const [updater, forceUpdate] = useReducer((x) => x + 1, 0);

  const { getAccessTokenSilently } = useAuth0();

  useEffect(() => {
    (async () => {
      const token = await getAccessTokenSilently();
      const { data: fetchedDocuments } = await axios.get(
        `${
          process.env.REACT_APP_SUZUKI_URL
        }/documents/entity/${tramitType?.toLowerCase()}/${tramitCode}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      );
      if (setContractSignatureDate) {
        setContractSignatureDate(
          fetchedDocuments.find((document) =>
            document.parts.includes('contract'),
          )?.metadata?.lastSignature ||
            fetchedDocuments.find((document) =>
              document.parts.includes('custom'),
            )?.metadata?.lastSignature ||
            '',
        );
      }
      setDocuments(fetchedDocuments);
    })();
  }, [updater]);

  const getDocName = (doc) => {
    if (doc.metadata?.type) {
      if (doc.metadata.type === 'Otros') return 'Personalizado';
      return doc.metadata.type;
    }
    return doc.parts.map((e) => partsDictionary.get(e)).join(' - ');
  };

  return (
    <>
      <Tooltip
        style={{
          marginTop: '0',
        }}
        id="doc-status-tooltip"
        place="top"
      />
      <>
        <DeleteDigitalSignatureModal
          docId={documentIdForDelete}
          save={forceUpdate}
          setShowModal={setOpenDeleteModal}
          showModal={openDeleteModal}
        />
        {tramitType === ENTITY_TYPES.TRANSACTION && (
          <DigitalSignatureModal
            isPro={false}
            save={forceUpdate}
            setShowModal={setOpenCreateModal}
            showModal={openCreateModal}
            transfer={tramit}
          />
        )}
        <CustomSignatureModal
          open={openCustomModal}
          setOpen={setOpenCustomModal}
          tramit={tramit}
          tramitType={tramitType?.toLowerCase()}
          tramitCode={tramitCode}
          forceUpdate={forceUpdate}
        />
      </>
      <ModuleWrapper
        title="Firma digital"
        button={
          <div className="flex flex-end gap-2">
            <button
              type="button"
              className="inline-flex gap-2 items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
              onClick={() => {
                setOpenCustomModal(true);
              }}
            >
              Personalizado <PlusIcon className="h-4" />
            </button>
            {tramitType === ENTITY_TYPES.TRANSACTION && (
              <button
                type="button"
                className="inline-flex gap-2 items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                onClick={() => {
                  setOpenCreateModal(true);
                }}
              >
                Nuevo contrato <PlusIcon className="h-4" />
              </button>
            )}
          </div>
        }
      >
        <div className="overflow-visible p-6">
          <div className="inline-block min-w-full align-middle">
            <div className="overflow-visible shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
              <table className="min-w-full divide-y divide-gray-300">
                <thead className="bg-gray-50">
                  <tr>
                    <th
                      scope="col"
                      className="py-3.5 pl-4 pr-3 text-sm font-semibold text-gray-900 sm:pl-6 text-center"
                    >
                      Documento
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-sm font-semibold text-gray-900 text-center"
                    >
                      Estado
                    </th>

                    <th
                      scope="col"
                      className="px-3 py-3.5 text-sm font-semibold text-gray-900 text-center"
                    >
                      {' '}
                    </th>
                  </tr>
                </thead>
                {documents.length === 0 && (
                  <tbody>
                    <tr>
                      <td className="text-center h-32 text-md" colSpan={3}>
                        No hay documentos relacionados a esta transferencia
                      </td>
                    </tr>
                  </tbody>
                )}
                {documents.length > 0 && (
                  <tbody className="divide-y divide-gray-200 bg-white">
                    {documents.map((doc) => (
                      <tr key={doc.id}>
                        <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6 text-center">
                          {getDocName(doc)}
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 text-center">
                          <span
                            data-tooltip-id={
                              doc.status === 'PENDING' ||
                              (doc.status === 'COMPLETED' &&
                                doc.metadata.lastSignature)
                                ? 'doc-status-tooltip'
                                : undefined
                            }
                            data-tooltip-content={getTooltipMessage(
                              doc,
                              doc.metadata?.lastSignature,
                            )}
                            className={`rounded-full px-3 py-0.5 text-sm font-medium cursor-default ${
                              statusBadgeDictionary.get(doc.status)?.color
                            }`}
                          >
                            {statusBadgeDictionary.get(doc.status)?.value}
                          </span>
                        </td>
                        {validateUUID(doc.serviceId) && (
                          <td className="text-end whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                            <span className="flex items-center justify-end gap-4">
                              <Menu
                                as="div"
                                className="relative inline-block text-left"
                              >
                                <div>
                                  <Menu.Button className="flex items-center">
                                    <span className="sr-only">
                                      Open options
                                    </span>
                                    <DotsVerticalIcon
                                      className="h-5 w-5"
                                      aria-hidden="true"
                                    />
                                  </Menu.Button>
                                </div>

                                <Transition
                                  as={Fragment}
                                  enter="transition ease-out duration-100"
                                  enterFrom="transform opacity-0 scale-95"
                                  enterTo="transform opacity-100 scale-100"
                                  leave="transition ease-in duration-75"
                                  leaveFrom="transform opacity-100 scale-100"
                                  leaveTo="transform opacity-0 scale-95"
                                >
                                  <Menu.Items className="absolute right-8 top-7 -translate-y-full z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                                    <div className="py-1">
                                      {extraActionsDictionary
                                        .get(doc.status)
                                        ?.map((action) => (
                                          <Menu.Item key={action.value}>
                                            {({ active }) => (
                                              <button
                                                type="button"
                                                className={classNames(
                                                  active
                                                    ? 'bg-gray-100 text-gray-900'
                                                    : 'text-gray-700',
                                                  'flex px-4 py-2 text-sm w-full items-center justify-start gap-3',
                                                )}
                                                onClick={() =>
                                                  action.action({
                                                    serviceId: doc.serviceId,
                                                    documentId: doc.id,
                                                    getToken:
                                                      getAccessTokenSilently,
                                                    setDocumentIdForDelete,
                                                    setOpenDeleteModal,
                                                  })
                                                }
                                              >
                                                {action.icon}
                                                {action.value}
                                              </button>
                                            )}
                                          </Menu.Item>
                                        ))}
                                    </div>
                                  </Menu.Items>
                                </Transition>
                              </Menu>
                            </span>
                          </td>
                        )}
                      </tr>
                    ))}
                  </tbody>
                )}
              </table>
            </div>
          </div>
        </div>
      </ModuleWrapper>
    </>
  );
}

export default SignatureDetails;
