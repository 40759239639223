import { useState, useEffect } from 'react';
import { Tooltip } from 'react-tooltip';
import { HiOutlineSwitchHorizontal } from 'react-icons/hi';
import {
  PaperClipIcon,
  BadgeCheckIcon,
  ExclamationIcon,
} from '@heroicons/react/solid';

import { isValidDNI, isValidNIE } from '../../../../helpers/doiValidators';
import { useServices } from '../../../../services';
import { parseDate } from '../../../../helpers';
import isUnderAge from '../../../../helpers/isUnderAge';
import { ccaaDictionary } from '../../../../helpers/enums';
import FileLink from '../../../../components/FileLink';
import isDoiCloseToExpire from '../../../../helpers/isDoiCloseToExpire';
import EditorModal from '../../../../components/EditorModal';
import UploadFileModal from '../../../../components/UploadFileModal';
import ReplaceRepresentativeModal from '../../transfers/components/ReplaceRepresentativeModal';

export default function CompanyAdministrator({
  companyId,
  representative,
  forceUpdate,
}) {
  const [modalType, setModalType] = useState(null);
  const [uploadModalType, setUploadModalType] = useState('');
  const [replaceRepresentativeModalOpen, setReplaceRepresentativeModalOpen] =
    useState(false);
  const [preloadedState, setPreloadedState] = useState(representative);

  const { editUser } = useServices();

  useEffect(() => {
    setPreloadedState(representative);
  }, [representative]);

  return (
    representative && (
      <>
        <EditorModal
          type={modalType}
          setType={setModalType}
          id={representative.id}
          save={forceUpdate}
          preloadedState={preloadedState}
        />
        <UploadFileModal
          entity={representative}
          modalType={uploadModalType}
          setModalType={setUploadModalType}
          entityKey={representative.id}
          editEntity={editUser}
          forceUpdate={forceUpdate}
        />
        <ReplaceRepresentativeModal
          isOpen={replaceRepresentativeModalOpen}
          setIsOpen={setReplaceRepresentativeModalOpen}
          companyId={companyId}
          updateCompanyDetails={forceUpdate}
        />
        <h3 className="text-2xl text-gray-900 mt-4 flex gap-2 items-center">
          Datos del representante{' '}
          <HiOutlineSwitchHorizontal
            className="hover:text-blue-500 cursor-pointer"
            onClick={() => setReplaceRepresentativeModalOpen(true)}
          />
        </h3>
        <h3 className="text-md text-black font-medium pt-4 border-none">
          Información de contacto
        </h3>
        <dl className="divide-y divide-gray-200 border-t">
          <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4">
            <dt className="text-sm font-medium text-gray-500">
              Nombre y apellidos
            </dt>
            <dd className="mt-1 flex text-sm text-gray-900 sm:mt-0 sm:col-span-2">
              <span className="flex-grow">
                {representative?.name || ''} {representative?.surname || ''}
              </span>
              <span className="ml-4 shrink-0">
                <button
                  type="button"
                  onClick={() => {
                    setModalType('name');
                  }}
                  className="rounded-md font-medium text-indigo-600 hover:text-indigo-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                >
                  Editar
                </button>
              </span>
            </dd>
          </div>
          <div className="py-4 sm:grid sm:py-5 sm:grid-cols-3 sm:gap-4">
            <dt className="text-sm font-medium text-gray-500">Domicilio</dt>
            <dd className="mt-1 flex text-sm text-gray-900 sm:mt-0 sm:col-span-2">
              <span className="flex-grow">
                {representative.address}
                {representative.city && `, ${representative.city}`}
              </span>
              <span className="ml-4 shrink-0">
                <button
                  type="button"
                  onClick={() => {
                    setModalType('address');
                  }}
                  className="rounded-md font-medium text-indigo-600 hover:text-indigo-500 focus:outline-none focus:ring-indigo-500"
                >
                  Editar
                </button>
              </span>
            </dd>
          </div>
          <div className="py-4 sm:grid sm:py-5 sm:grid-cols-3 sm:gap-4">
            <dt className="text-sm font-medium text-gray-500">
              Comunidad Autónoma
            </dt>
            <dd className="mt-1 flex text-sm text-gray-900 sm:mt-0 sm:col-span-2">
              <span className="flex-grow">
                {ccaaDictionary[representative.ccaa]}
              </span>
              <span className="ml-4 shrink-0">
                <button
                  type="button"
                  onClick={() => {
                    setModalType('ccaa');
                  }}
                  className="rounded-md font-medium text-indigo-600 hover:text-indigo-500 focus:outline-none focus:ring-indigo-500"
                >
                  Editar
                </button>
              </span>
            </dd>
          </div>
          <div className="py-4 sm:grid sm:py-5 sm:grid-cols-3 sm:gap-4">
            <dt className="text-sm font-medium text-gray-500">DNI</dt>
            <dd className="mt-1 flex text-sm text-gray-900 sm:mt-0 sm:col-span-2">
              <span className="flex-grow">{representative.dni}</span>
              {representative.dni && (
                <span className="flex-grow">
                  {isValidDNI(representative.dni) ||
                  isValidNIE(representative.dni) ? (
                    <BadgeCheckIcon className="text-teal-500 h-6" />
                  ) : (
                    <ExclamationIcon className="text-red-500 h-6" />
                  )}
                </span>
              )}
              <span className="ml-4 shrink-0">
                <button
                  type="button"
                  onClick={() => {
                    setModalType('dni');
                  }}
                  className="rounded-md font-medium text-indigo-600 hover:text-indigo-500 focus:outline-none focus:ring-indigo-500"
                >
                  Editar
                </button>
              </span>
            </dd>
          </div>
          <div className="py-4 sm:grid sm:py-5 sm:grid-cols-3 sm:gap-4">
            <dt className="text-sm font-medium text-gray-500">
              Fecha de caducidad (DNI)
            </dt>
            <dd className="mt-1 flex text-sm text-gray-900 sm:mt-0 sm:col-span-2">
              <span className="flex-grow">
                {parseDate(representative.dniExpiryDate)}
              </span>
              {representative.dniExpiryDate &&
                isDoiCloseToExpire(representative.dniExpiryDate) && (
                  <span className="flex-grow">
                    <ExclamationIcon className="text-yellow-500 h-6" />
                  </span>
                )}
              <span className="ml-4 shrink-0">
                <button
                  type="button"
                  onClick={() => {
                    setModalType('dniExpiryDate');
                  }}
                  className="rounded-md font-medium text-indigo-600 hover:text-indigo-500 focus:outline-none focus:ring-indigo-500"
                >
                  Editar
                </button>
              </span>
            </dd>
          </div>
          <div className="py-4 sm:grid sm:py-5 sm:grid-cols-3 sm:gap-4">
            <dt className="text-sm font-medium text-gray-500">
              Fecha de nacimiento
            </dt>
            <dd className="mt-1 flex text-sm text-gray-900 sm:mt-0 sm:col-span-2">
              <span className="flex-grow">
                {parseDate(representative.birthDate)}
              </span>
              {representative.birthDate &&
                isUnderAge(representative.birthDate) && (
                  <span className="flex-grow">
                    <Tooltip
                      id="warning-under-age"
                      style={{
                        marginTop: '0',
                        fontSize: '15px',
                      }}
                      content="Menor de edad"
                      place="top"
                    />
                    <ExclamationIcon
                      data-tooltip-id="warning-under-age"
                      className="text-yellow-500 h-6"
                    />
                  </span>
                )}
              <span className="ml-4 shrink-0">
                <button
                  type="button"
                  onClick={() => {
                    setModalType('birthDate');
                  }}
                  className="rounded-md font-medium text-indigo-600 hover:text-indigo-500 focus:outline-none focus:ring-indigo-500"
                >
                  Editar
                </button>
              </span>
            </dd>
          </div>
          <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4">
            <dt className="text-sm font-medium text-gray-500">
              Teléfono móvil
            </dt>
            <dd className="mt-1 flex text-sm text-gray-900 sm:mt-0 sm:col-span-2">
              <span className="flex-grow">{representative?.phone}</span>
              <span className="ml-4 shrink-0">
                <button
                  type="button"
                  onClick={() => {
                    setModalType('phone');
                  }}
                  className="rounded-md font-medium text-indigo-600 hover:text-indigo-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                >
                  Editar
                </button>
              </span>
            </dd>
          </div>
          <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4">
            <dt className="text-sm font-medium text-gray-500">
              Correo electrónico
            </dt>
            <dd className="mt-1 flex text-sm text-gray-900 sm:mt-0 sm:col-span-2">
              <span className="flex-grow">{representative?.email}</span>
              <span className="ml-4 shrink-0">
                <button
                  type="button"
                  onClick={() => {
                    setModalType('email');
                  }}
                  className="rounded-md font-medium text-indigo-600 hover:text-indigo-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                >
                  Editar
                </button>
              </span>
            </dd>
          </div>
          <div className="py-4 sm:grid sm:py-5 sm:grid-cols-3 sm:gap-4">
            <dt className="text-md text-black font-medium">Documentos</dt>
            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
              <ul className="border border-gray-200 rounded-md divide-y divide-gray-200">
                <li className="pl-3 pr-4 py-3 flex items-center justify-between text-sm">
                  <div className="w-0 flex-1 flex items-center">
                    <PaperClipIcon
                      className="shrink-0 h-5 w-5 text-gray-400"
                      aria-hidden="true"
                    />
                    <span className="ml-2 flex-1 w-0 truncate">
                      DNI apoderado - cara
                    </span>
                  </div>
                  <div className="ml-4 shrink-0 flex space-x-4">
                    {Array.isArray(representative?.photoDni) &&
                    representative?.photoDni[0] ? (
                      <>
                        <FileLink source={representative?.photoDni[0]} />
                        <span className="text-gray-300" aria-hidden="true">
                          |
                        </span>
                        <button
                          type="button"
                          onClick={() => {
                            setUploadModalType('dniFront');
                          }}
                          className="rounded-md font-medium text-indigo-600 hover:text-indigo-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                        >
                          Reemplazar
                        </button>
                      </>
                    ) : (
                      <button
                        type="button"
                        className="rounded-md font-medium text-indigo-600 hover:text-indigo-500 focus:outline-none focus:ring-indigo-500"
                        onClick={() => {
                          setUploadModalType('dniFront');
                        }}
                      >
                        Añadir
                      </button>
                    )}
                  </div>
                </li>
                <li className="pl-3 pr-4 py-3 flex items-center justify-between text-sm">
                  <div className="w-0 flex-1 flex items-center">
                    <PaperClipIcon
                      className="shrink-0 h-5 w-5 text-gray-400"
                      aria-hidden="true"
                    />
                    <span className="ml-2 flex-1 w-0 truncate">
                      DNI apoderado - reverso
                    </span>
                  </div>
                  <div className="ml-4 shrink-0 flex space-x-4">
                    {Array.isArray(representative?.photoDni) &&
                    representative?.photoDni[1] ? (
                      <>
                        <FileLink source={representative?.photoDni[1]} />
                        <span className="text-gray-300" aria-hidden="true">
                          |
                        </span>
                        <button
                          type="button"
                          onClick={() => {
                            setUploadModalType('dniBack');
                          }}
                          className="rounded-md font-medium text-indigo-600 hover:text-indigo-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                        >
                          Reemplazar
                        </button>
                      </>
                    ) : (
                      <button
                        type="button"
                        className="rounded-md font-medium text-indigo-600 hover:text-indigo-500 focus:outline-none focus:ring-indigo-500"
                        onClick={() => {
                          setUploadModalType('dniBack');
                        }}
                      >
                        Añadir
                      </button>
                    )}
                  </div>
                </li>
                <li className="pl-3 pr-4 py-3 flex items-center justify-between text-sm">
                  <div className="w-0 flex-1 flex items-center">
                    <PaperClipIcon
                      className="shrink-0 h-5 w-5 text-gray-400"
                      aria-hidden="true"
                    />
                    <span className="ml-2 flex-1 w-0 truncate">Mandato</span>
                  </div>
                  <div className="ml-4 shrink-0 flex space-x-4">
                    {representative.mandate ? (
                      <>
                        <FileLink source={representative.mandate} />
                        <span className="text-gray-300" aria-hidden="true">
                          |
                        </span>
                        <button
                          type="button"
                          onClick={() => setUploadModalType('mandate')}
                          className="rounded-md font-medium text-indigo-600 hover:text-indigo-500 focus:outline-none focus:ring-indigo-500"
                        >
                          Reemplazar
                        </button>
                      </>
                    ) : (
                      <button
                        type="button"
                        onClick={() => setUploadModalType('mandate')}
                        className="rounded-md font-medium text-indigo-600 hover:text-indigo-500 focus:outline-none focus:ring-indigo-500"
                      >
                        Añadir
                      </button>
                    )}
                  </div>
                </li>
              </ul>
            </dd>
          </div>
        </dl>
      </>
    )
  );
}
