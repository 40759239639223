import React, { Dispatch, SetStateAction } from 'react';
import { FaGlobeAmericas } from 'react-icons/fa';

import { Template as TemplateType } from '../ChooseTemplateStep';
import { parseDayMonthYearHourMinute } from '../../../../../helpers/parseDate';
import classNames from '../../../../../helpers/classNames';

type Props = Readonly<{
  template: TemplateType;
  setPreviewTemplateId: Dispatch<SetStateAction<string>>;
  previewTemplateId: string;
}>;

export default function Template({
  template,
  previewTemplateId,
  setPreviewTemplateId,
}: Props) {
  const isSelected = previewTemplateId === template.id;
  return (
    <button
      type="button"
      className={classNames(
        'flex w-full justify-between items-center p-4 gap-4 transition rounded-2xl',
        isSelected ? 'bg-blue-50' : 'border-transparent hover:bg-zinc-50',
      )}
      onKeyDown={() => setPreviewTemplateId(template.id)}
      onClick={() => setPreviewTemplateId(template.id)}
    >
      <section className="flex items-center gap-5 w-full">
        <figure className="relative flex justify-center bg-zinc-100 rounded-lg size-16 min-w-16">
          {template.default && (
            <figcaption className="absolute -top-2 -left-2 bg-blue-800 p-1 rounded-lg">
              <FaGlobeAmericas className="w-3.5 h-3.5 text-white" />
            </figcaption>
          )}
          <img
            src={template.thumbnailLink}
            alt="thumbnail"
            referrerPolicy="no-referrer"
            className="h-full"
          />
        </figure>

        <section className="flex flex-col items-start gap-1 w-full">
          <article className="flex w-full gap-3">
            <span className="font-bold truncate">{template?.name}</span>
            {template?.type && (
              <span className="border border-zinc-400 bg-zinc-100 h-fit px-2 py-1 rounded-xl text-xs text-zinc-800">
                {template.type}
              </span>
            )}
          </article>
          <article className="text-sm text-zinc-700 opacity-60">
            Última actualización:{' '}
            {parseDayMonthYearHourMinute(new Date(template.modifiedTime))}
          </article>
        </section>
      </section>
    </button>
  );
}
