import React, {
  createContext,
  Dispatch,
  SetStateAction,
  useMemo,
  useState,
} from 'react';

import { DigitallySignSteps } from '../../modals/DigitallySignModal/steps/DigitallySignStepsManager';
import { DocumentSourceOptions } from '../../modals/DigitallySignModal/steps/ChooseDocumentSourceStep/ChooseDocumentSourceStep';
import { DocumentPDF } from '../../modals/DigitallySignModal/types/types';
import { SendingMethod } from '../../modals/DigitallySignModal/steps/ChooseRecipientsStep/components/RecipientSendingMethod';

export type DigitallySignContextType = {
  currentStep: number;
  setCurrentDigitallySignStep: Dispatch<SetStateAction<DigitallySignSteps>>;
  generatedDocumentId: string | null;
  setGeneratedDocumentId: Dispatch<SetStateAction<string | null>>;
  chosenDocumentSource: DocumentSourceOptions | null;
  setChosenDocumentSource: Dispatch<
    SetStateAction<DocumentSourceOptions | null>
  >;
  documentPDF: DocumentPDF | null;
  setDocumentPDF: Dispatch<SetStateAction<DocumentPDF | null>>;
  buyerSendingMethod: SendingMethod | '';
  setBuyerSendingMethod: Dispatch<SetStateAction<SendingMethod>>;
  sellerSendingMethod: SendingMethod | '';
  setSellerSendingMethod: Dispatch<SetStateAction<SendingMethod>>;
  isMotoOcasionAccount: (organizationId: string) => boolean;
};

export const digitallySignContext = createContext<DigitallySignContextType>(
  {} as DigitallySignContextType,
);

export function DigitallySignContextProvider({
  children,
}: {
  readonly children: React.ReactNode;
}) {
  const [generatedDocumentId, setGeneratedDocumentId] = useState<string | null>(
    null,
  );
  const [currentDigitallySignStep, setCurrentDigitallySignStep] =
    useState<DigitallySignSteps>(DigitallySignSteps.CHOOSE_DOCUMENT_SOURCE);
  const [chosenDocumentSource, setChosenDocumentSource] =
    useState<DocumentSourceOptions>(null);
  const [documentPDF, setDocumentPDF] = useState<DocumentPDF>(null);
  const [buyerSendingMethod, setBuyerSendingMethod] = useState<
    SendingMethod | ''
  >('');
  const [sellerSendingMethod, setSellerSendingMethod] = useState<
    SendingMethod | ''
  >('');

  const isMotoOcasionAccount = (organizationId: string) => {
    if (process.env.REACT_APP_ENV === 'PROD') {
      return organizationId === 'org_bd2QmYRtLF3l0QOU';
    }
    return organizationId === 'org_WaZomy6KMwMq62mo';
  };

  const value = useMemo(
    () => ({
      currentStep: currentDigitallySignStep,
      setCurrentDigitallySignStep,
      generatedDocumentId,
      setGeneratedDocumentId,
      chosenDocumentSource,
      setChosenDocumentSource,
      documentPDF,
      setDocumentPDF,
      setBuyerSendingMethod,
      buyerSendingMethod,
      sellerSendingMethod,
      setSellerSendingMethod,
      isMotoOcasionAccount,
    }),
    [
      currentDigitallySignStep,
      generatedDocumentId,
      documentPDF,
      buyerSendingMethod,
      sellerSendingMethod,
    ],
  );

  return (
    <digitallySignContext.Provider value={value}>
      {children}
    </digitallySignContext.Provider>
  );
}
