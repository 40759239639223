/* eslint-disable react/jsx-props-no-spreading */
import { DragDropContext } from '@hello-pangea/dnd';
import { useEffect, useState } from 'react';
import Column from './components/column';
import ItpCalculator from './components/itpCalculator';
import labelEditor from './components/labelEditor';
import BannerConfig from './components/bannerConfig';

export default function Tools() {
  const [data, setData] = useState({
    isChecking: true,
    tools: {
      ItpCalculator: { id: 'ItpCalculator', component: ItpCalculator },
      BannerConfig: { id: 'BannerConfig', component: BannerConfig },
      LabelEditor: { id: 'LabelEditor', component: labelEditor },
    },
    columns: {
      'column-1': {
        id: 'column-1',
        toolIds: ['ItpCalculator', 'LabelEditor'],
      },
      'column-2': {
        id: 'column-2',
        toolIds: ['BannerConfig'],
      },
    },
    columnOrder: ['column-1', 'column-2'],
  });

  const onDragEnd = (result) => {
    const { destination, source, draggableId } = result;

    if (!destination) return;

    const hasMoved =
      destination.droppableId !== source.droppableId ||
      destination.index !== source.index;

    if (!hasMoved) return;

    const startColumn = data.columns[source.droppableId];
    const finishColumn = data.columns[destination.droppableId];

    if (startColumn === finishColumn) {
      const newToolIds = [...startColumn.toolIds];
      newToolIds.splice(source.index, 1);
      newToolIds.splice(destination.index, 0, draggableId);

      const newColumn = { ...startColumn, toolIds: newToolIds };

      setData({
        ...data,
        columns: { ...data.columns, [newColumn.id]: newColumn },
      });
    }

    if (startColumn !== finishColumn) {
      const newToolIds = [...startColumn.toolIds];
      newToolIds.splice(source.index, 1);

      const newStartingColumn = {
        ...startColumn,
        toolIds: newToolIds,
      };

      const finishToolIds = [...finishColumn.toolIds];
      finishToolIds.splice(destination.index, 0, draggableId);
      const newFinishColumn = { ...finishColumn, toolIds: finishToolIds };
      setData({
        ...data,
        columns: {
          ...data.columns,
          [newStartingColumn.id]: newStartingColumn,
          [newFinishColumn.id]: newFinishColumn,
        },
      });
    }
  };

  useEffect(() => {
    let toolsOrder = localStorage.getItem('toolsOrder');

    if (!toolsOrder) {
      localStorage.setItem('toolsOrder', JSON.stringify(data.columns));
    }

    toolsOrder = JSON.parse(localStorage.getItem('toolsOrder'));

    const toolsInLocalStorage = Object.values(toolsOrder)
      .map((e) => e.toolIds)
      .flat();

    const toolsInCode = Object.keys(data.tools);

    if (toolsInLocalStorage.length < toolsInCode.length) {
      toolsOrder['column-1'].toolIds.push(
        toolsInCode.find((e) => !toolsInLocalStorage.includes(e)),
      );
    }

    if (toolsInLocalStorage.length > toolsInCode.length) {
      toolsOrder = {
        'column-1': {
          ...toolsOrder['column-1'],
          toolIds: toolsOrder['column-1'].toolIds.filter((e) =>
            toolsInCode.includes(e),
          ),
        },
        'column-2': {
          ...toolsOrder['column-2'],
          toolIds: toolsOrder['column-2'].toolIds.filter((e) =>
            toolsInCode.includes(e),
          ),
        },
      };
    }

    localStorage.setItem('toolsOrder', JSON.stringify(toolsOrder));

    setData({
      ...data,
      columns: JSON.parse(localStorage.getItem('toolsOrder')),
      isChecking: false,
    });
  }, []);

  useEffect(() => {
    localStorage.setItem('toolsOrder', JSON.stringify(data.columns));
  }, [data]);

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      {!data.isChecking && (
        <div className="flex gap-x-4">
          {data.columnOrder.map((columnId) => {
            const column = data.columns[columnId];
            const tools = column.toolIds.map((toolId) => data.tools[toolId]);
            return <Column key={columnId} column={column} tools={tools} />;
          })}
        </div>
      )}
    </DragDropContext>
  );
}
