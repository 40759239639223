import { useEffect, useState } from 'react';
import { Switch } from '@headlessui/react';

import { useServices } from '../../../../services';
import classNames from '../../../../helpers/classNames';

const bannerDict = {
  'banner-christmas-days': {
    title: 'Navidad',
    description:
      'Los días 24, 26 y 31 de Diciembre DGT no permitirá a las gestorías presentar ni finalizar trámites.',
  },
  'banner-catalonia-national-day': {
    title: 'Diada cataluña',
    description:
      'El día 11 de Septiembre DGT no permitirá a las gestorías presentar ni finalizar trámites.',
  },
  'banner-dgt-down': {
    title: 'DGT fuera de servicio',
    description:
      'En estos momentos DGT está temporalmente fuera de servicio. Esto puede ocasionar retrasos en la finalización de los trámites, la gestión será completada tan pronto como DGT restablezca su funcionamiento.',
  },
};

export default function BannerConfig() {
  const [banners, setBanners] = useState([]);

  const { getFeatureFlags, updateFeatureFlag } = useServices();

  const fetchBanners = async () => {
    const flags = await getFeatureFlags();
    const mergeBannerInfo = flags.result
      .map((flag) => {
        if (Object.keys(bannerDict).includes(flag.key)) {
          return {
            ...bannerDict[flag.key],
            active: flag.active,
            key: flag.key,
            id: flag.id,
          };
        }
        return null;
      })
      .filter(Boolean);
    setBanners(mergeBannerInfo);
  };

  const handleOnChange = async (id, checked) => {
    await updateFeatureFlag(id, { active: checked });
    const newBannerState = banners.map((banner) =>
      banner.id === id ? { ...banner, active: checked } : banner,
    );
    setBanners(newBannerState);
  };

  useEffect(() => {
    fetchBanners();
  }, []);

  return (
    <div className="space-y-8 border-2 px-8 pb-4 rounded-lg w-full h-[fit-content] min-h-[230px]">
      <div className="space-y-8 divide-y divide-gray-200 sm:space-y-5">
        <div className="space-y-6 sm:pt-4 sm:space-y-5">
          <div>
            <h3 className="text-lg leading-6 font-medium text-gray-900">
              Configuración banners
            </h3>
          </div>
        </div>
        {banners.map((banner) => (
          <Switch.Group
            as="div"
            key={banner.key}
            className="flex items-center justify-between"
          >
            <span className="flex grow flex-col mr-2 mt-4">
              <Switch.Label
                as="span"
                passive
                className="text-sm/6 font-medium text-gray-900"
              >
                {banner.title}
              </Switch.Label>
              <Switch.Description as="span" className="text-sm text-gray-500">
                {banner.description}
              </Switch.Description>
            </span>
            <Switch
              id={banner.id}
              name={banner.key}
              checked={banner.active}
              onChange={(checked) => handleOnChange(banner.id, checked)}
              className={classNames(
                banner.active ? 'bg-blue-600' : 'bg-gray-200',
                'group relative inline-flex h-6 w-11 shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-blue-600 focus:ring-offset-2 mt-4',
              )}
            >
              <span
                aria-hidden="true"
                className={classNames(
                  banner.active && 'translate-x-5',
                  'pointer-events-none inline-block size-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out',
                )}
              />
            </Switch>
          </Switch.Group>
        ))}
      </div>
    </div>
  );
}
