import { UserIcon, OfficeBuildingIcon } from '@heroicons/react/outline';
import React, { useContext, useState, useMemo } from 'react';
import { FaCarSide, FaMotorcycle } from 'react-icons/fa';

import RelatedBateTramit from './components/RelatedBateTramit';
import { transferDetailsContext } from '../../../context/TransferDetailsContext/transferDetailsContext';
import { userInfoContext } from '../../../context/UserInfoContext/UserInfoContext';
import EditPartModal from '../../../modals/EditPartModal/EditPartModal';
import EditVehicleModal from '../../../modals/EditVehicleModal/EditVehicleModal';
import { Car, Company, User } from '../../../types/types';
import Card from './components/Card';
import ReplaceCounterpartModal, {
  PossibleCounterpart,
} from '../../../components/ReplaceCounterpartModal';
import ReplaceVehicleModal from '../../../components/ReplaceVehicleModal';
import {
  generateCompanySection,
  generateUserSection,
  generateVehicleSection,
  generateCompanyFileSection,
  generateUserFileSection,
  generateVehicleFiles,
} from '../../../helpers/generateCardSections';
import { getCounterpartEntityType } from '../../../helpers/getCounterpartEntityType';

function GeneralInfo() {
  const { transfer, forceUpdate } = useContext(transferDetailsContext);
  const { companyInfo } = useContext(userInfoContext);

  const [replaceModalData, setReplaceModalData] = useState<{
    counterPart: PossibleCounterpart;
    open: boolean;
  }>({
    counterPart: 'buyer',
    open: false,
  });

  const [replaceVehicleModalOpen, setReplaceVehicleModalOpen] = useState(false);

  const buyer = transfer?.buyer;
  const seller = transfer?.seller;
  const vehicle = transfer?.car;
  const transaction = transfer?.transaction;

  const [editPartModalInfo, setEditPartModalInfo] = useState<{
    isOpen: boolean;
    type: 'Comprador' | 'Vendedor';
    preloadedValue: User | Company;
  }>({
    isOpen: false,
    type: 'Comprador',
    preloadedValue: null,
  });
  const [editVehicleModalInfo, setEditVehicleModalInfo] = useState<{
    isOpen: boolean;
    preloadedValue: Car;
  }>({
    isOpen: false,
    preloadedValue: null,
  });

  const setEditPartModalOpen = (value: boolean) =>
    setEditPartModalInfo({
      ...editPartModalInfo,
      isOpen: value,
    });

  const setEditVehicleModalOpen = (value: boolean) =>
    setEditVehicleModalInfo({
      ...editVehicleModalInfo,
      isOpen: value,
    });

  const setReplaceModalOpen = (value: boolean): void => {
    setReplaceModalData({
      ...replaceModalData,
      open: value,
    });
  };

  const replaceCounterpartType = useMemo(
    getCounterpartEntityType({
      counterPartRole: replaceModalData.counterPart,
      buyer,
      seller,
    }),
    [buyer, replaceModalData.counterPart, seller],
  );

  if (!transfer) return null;

  return (
    <>
      <ReplaceCounterpartModal
        counterpart={replaceModalData.counterPart}
        open={replaceModalData.open}
        setOpen={setReplaceModalOpen}
        forceUpdate={forceUpdate}
        sourceEntityId={
          replaceModalData.counterPart === 'buyer' ? buyer?.id : seller?.id
        }
        type={replaceCounterpartType}
      />
      <ReplaceVehicleModal
        open={replaceVehicleModalOpen}
        setOpen={setReplaceVehicleModalOpen}
        forceUpdate={forceUpdate}
        sourceCarId={vehicle?.id}
      />
      <EditPartModal
        isOpen={editPartModalInfo.isOpen}
        setIsOpen={setEditPartModalOpen}
        onSave={() => {
          forceUpdate();
        }}
        preloadedValue={editPartModalInfo.preloadedValue}
        type={editPartModalInfo.type}
      />
      <EditVehicleModal
        procedureType="TRANSACTION"
        code={transaction?.transactionCode}
        isOpen={editVehicleModalInfo.isOpen}
        setIsOpen={setEditVehicleModalOpen}
        onSave={() => {
          setEditVehicleModalOpen(false);
          forceUpdate();
        }}
        preloadedValue={{
          price: transaction?.priceContractValue,
          ...editVehicleModalInfo.preloadedValue,
        }}
      />
      {transaction.isBatecom && (
        <RelatedBateTramit transactionId={transaction.id} />
      )}
      {buyer && companyInfo?.nif !== (buyer as Company).nif && (
        <Card
          title="Comprador"
          subtitle={
            (buyer as Company).representativeId ? 'Empresa' : 'Particular'
          }
          subtitleIcon={
            (buyer as Company).representativeId ? (
              <OfficeBuildingIcon className="h-4" />
            ) : (
              <UserIcon className="h-4" />
            )
          }
          sections={
            (buyer as Company).representativeId
              ? [
                  generateCompanySection(buyer as Company),
                  (buyer as Company).representative &&
                    generateUserSection(
                      (buyer as Company).representative,
                      'Administrador de la empresa',
                    ),
                ]
              : [generateUserSection(buyer as User, 'Datos personales')]
          }
          files={
            (buyer as Company).representativeId
              ? generateCompanyFileSection(buyer as Company)
              : generateUserFileSection(buyer as User)
          }
          onEdit={() => {
            setEditPartModalInfo({
              isOpen: true,
              type: 'Comprador',
              preloadedValue: buyer,
            });
          }}
          onReplace={() => {
            setReplaceModalData({
              open: true,
              counterPart: 'buyer',
            });
          }}
        />
      )}
      {seller && companyInfo?.nif !== (seller as Company).nif && (
        <Card
          title="Vendedor"
          subtitle={
            (seller as Company).representativeId ? 'Empresa' : 'Particular'
          }
          subtitleIcon={
            (seller as Company).representativeId ? (
              <OfficeBuildingIcon className="h-4" />
            ) : (
              <UserIcon className="h-4" />
            )
          }
          sections={
            (seller as Company).representativeId
              ? [
                  generateCompanySection(seller as Company),
                  (seller as Company).representative &&
                    generateUserSection(
                      (seller as Company).representative,
                      'Administrador de la empresa',
                    ),
                ]
              : [generateUserSection(seller as User, 'Datos personales')]
          }
          files={
            (seller as Company).representativeId
              ? generateCompanyFileSection(seller as Company)
              : generateUserFileSection(seller as User)
          }
          onEdit={() => {
            setEditPartModalInfo({
              isOpen: true,
              type: 'Vendedor',
              preloadedValue: seller,
            });
          }}
          onReplace={() => {
            setReplaceModalData({
              open: true,
              counterPart: 'seller',
            });
          }}
        />
      )}
      {vehicle && (
        <Card
          title="Vehículo"
          subtitle={vehicle?.isMoto ? 'Moto' : 'Coche'}
          subtitleIcon={
            vehicle?.isMoto ? (
              <FaMotorcycle className="h-4" />
            ) : (
              <FaCarSide className="h-4" />
            )
          }
          sections={generateVehicleSection({
            price: transfer?.transaction?.priceContractValue,
            ...vehicle,
          })}
          files={generateVehicleFiles(vehicle)}
          onReplace={() => {
            setReplaceVehicleModalOpen(true);
          }}
          onEdit={() =>
            setEditVehicleModalInfo({ isOpen: true, preloadedValue: vehicle })
          }
        />
      )}
    </>
  );
}

export default GeneralInfo;
