import axios from 'axios';
import { useEffect, useMemo, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import {
  AsyncState,
  BateTramit,
  RegistrationTramit,
  Transfer,
  TemplateType,
} from '../../../../../types/types';
import { Template } from '../ChooseTemplateStep';
import { generateTextReplacements } from '../helpers/generateTextReplacements';

type TemplateResponse = {
  id: string;
  name: string;
  modifiedTime: string;
  thumbnailLink: string;
  appProperties: {
    type: TemplateType;
  };
};

export default function useTemplates(templateType: TemplateType) {
  const [selectedTemplateId, setSelectedTemplateId] = useState<string | null>(
    null,
  );
  const [templates, setTemplates] = useState<AsyncState<Template[]>>({
    data: [],
    error: '',
    isLoading: true,
  });
  const [injectedDocumentId, setInjectedDocumentId] = useState<
    AsyncState<string | null>
  >({
    data: null,
    isLoading: false,
    error: '',
  });

  const { getAccessTokenSilently } = useAuth0();

  const injectTemplate = async (
    tramit: Transfer | BateTramit | RegistrationTramit,
  ): Promise<string> => {
    try {
      setInjectedDocumentId((prev) => ({
        ...prev,
        isLoading: true,
        error: '',
      }));

      const token = await getAccessTokenSilently();

      const replacements = generateTextReplacements(tramit);

      const template = templates.data.find((t) => t.id === selectedTemplateId);
      const { value: plate } = replacements.find(
        (r) => r.placeholder === '{{vehiculo_matrícula}}',
      );

      const {
        data: { newDocumentId },
      } = await axios.post(
        `${process.env.REACT_APP_SUZUKI_URL}/templates/use/${selectedTemplateId}`,
        {
          replacements,
          title: `${template?.name} ${plate}`,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      );

      return newDocumentId;
    } catch (e) {
      setInjectedDocumentId((prev) => ({
        ...prev,
        error: 'Ha ocurrido un error generando el documento',
      }));
      return '';
    } finally {
      setInjectedDocumentId((prev) => ({
        ...prev,
        isLoading: false,
        error: '',
      }));
    }
  };

  useEffect(() => {
    (async () => {
      try {
        const token = await getAccessTokenSilently();

        const [{ data: customTemplates }, { data: defaultTemplates }] =
          await Promise.all([
            axios.get(
              `${process.env.REACT_APP_SUZUKI_URL}/templates?type=${templateType}`,
              {
                headers: {
                  Authorization: `Bearer ${token}`,
                },
              },
            ),
            axios.get(
              `${process.env.REACT_APP_SUZUKI_URL}/templates/default?type=${templateType}`,
              {
                headers: {
                  Authorization: `Bearer ${token}`,
                },
              },
            ),
          ]);

        const data = [
          ...customTemplates.map((t: TemplateResponse) => ({
            id: t.id,
            name: t.name,
            modifiedTime: t.modifiedTime,
            thumbnailLink: t.thumbnailLink,
            type: t.appProperties.type,
            default: false,
          })),
          ...defaultTemplates.map((t: TemplateResponse) => ({
            id: t.id,
            name: t.name,
            modifiedTime: t.modifiedTime,
            thumbnailLink: t.thumbnailLink,
            type: t.appProperties.type,
            default: true,
          })),
        ];
        setTemplates((prev) => ({
          ...prev,
          data,
        }));
      } catch (e) {
        setTemplates((prev) => ({
          ...prev,
          error: 'Ha ocurrido un error al cargar las plantillas',
        }));
      } finally {
        setTemplates((prev) => ({ ...prev, isLoading: false }));
      }
    })();
  }, []);

  const value = useMemo(
    () => ({
      templates,
      selectedTemplateId,
      setSelectedTemplateId,
      injectTemplate,
      injectedDocumentId,
    }),
    [
      templates,
      selectedTemplateId,
      setSelectedTemplateId,
      injectTemplate,
      injectedDocumentId,
    ],
  );

  return value;
}
