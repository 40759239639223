import React, { useState, useContext } from 'react';
import {
  ChatIcon,
  InformationCircleIcon,
  DocumentDuplicateIcon,
} from '@heroicons/react/outline';

import Documents from '../Modules/Documents';
import Comments from '../Modules/Comments/Comments';
import GeneralInfo from './tabs/GeneralInfo';
import BateDetailsHeader from './header';
import {
  bateDetailsContext,
  BateDetailsContextProvider,
} from '../context/BateDetailsContext/bateDetailsContext';

export type Tab = {
  id: keyof TabsType;
  title: string;
  icon: JSX.Element;
};

interface IObjectKeys {
  [key: string]: Tab;
}

export interface TabsType extends IObjectKeys {
  information?: Tab;
  documents?: Tab;
  digitalSignature?: Tab;
}

function BateDetails() {
  const tabs: TabsType = {
    information: {
      id: 'information',
      title: 'Información',
      icon: (
        <InformationCircleIcon
          className="h-5 w-5 mb-2.5"
          data-testid="svg-information"
        />
      ),
    },
    documents: {
      id: 'documents',
      title: 'Documentación',
      icon: (
        <DocumentDuplicateIcon
          className="h-5 w-5 mb-2.5"
          data-testid="svg-documentation"
        />
      ),
    },
    comments: {
      id: 'comments',
      title: 'Comentarios',
      icon: <ChatIcon className="h-5 w-5 mb-2.5" data-testid="svg-comments" />,
    },
  };

  const [currentTab, setCurrentTab] = useState<Tab>(tabs.information);

  const { bateTramit, bateLoading, forceUpdate, relatedTramit } =
    useContext(bateDetailsContext);

  const handleChangeTab = (tabId: string) => () => {
    setCurrentTab(Object.values(tabs).find((tab) => tab.id === tabId));
  };

  const getComponent = (tabId: keyof TabsType): JSX.Element => {
    if (tabId === 'documents') {
      return (
        <Documents
          tramit={bateTramit}
          entityType="bate"
          forceUpdate={forceUpdate}
        />
      );
    }
    if (tabId === 'comments') {
      return (
        <Comments
          entityId={bateTramit?.bate?.id}
          tramitCode={bateTramit?.bate?.bateCode}
          tramitType="BATE"
        />
      );
    }
    return <GeneralInfo />;
  };

  return (
    <div className="bg-gray-100 py-10 flex justify-center min-h-screen">
      <div className="w-2/3">
        <BateDetailsHeader
          tabs={tabs}
          isLoading={bateLoading}
          bateTramit={bateTramit}
          currentTab={currentTab}
          handleChangeTab={handleChangeTab}
          showContinueWithBatecom={!relatedTramit}
        />
        {getComponent(currentTab.id)}
      </div>
    </div>
  );
}

export default function BateDetailsWrapper() {
  return (
    <BateDetailsContextProvider>
      <BateDetails />
    </BateDetailsContextProvider>
  );
}
