import React, { useEffect, useState } from 'react';
import { MdClose, MdSend } from 'react-icons/md';
import { useAuth0 } from '@auth0/auth0-react';
import axios from 'axios';
import BaseModal from '../../components/BaseModal';
import {
  BateTramit,
  RegistrationTramit,
  Signer,
  Transfer,
  User,
} from '../../types/types';
import RecipientItem, {
  AvailableRecipientRoles,
} from '../DigitallySignModal/steps/ChooseRecipientsStep/components/RecipientItem';
import { getRecipient } from '../DigitallySignModal/types/Recipient';
import { isRegistrationTramit } from '../../types/RegistrationTramit';
import { SendingMethod } from '../DigitallySignModal/steps/ChooseRecipientsStep/components/RecipientSendingMethod';

type ResendSignatureRequestModalProps = {
  isShown: boolean;
  hide: () => void;
  procedure: Transfer | BateTramit | RegistrationTramit;
  suzukiDocumentId: string;
  signers: Signer[];
};

const hasCommunicationMethod = (recipient: User) => {
  if (!recipient) {
    return false;
  }

  return recipient.email?.length > 0 || recipient.phone?.length > 0;
};

export default function ResendSignatureRequestModal({
  isShown,
  hide,
  procedure,
  suzukiDocumentId,
  signers,
}: Readonly<ResendSignatureRequestModalProps>) {
  const { getAccessTokenSilently } = useAuth0();
  const [localBuyer, setLocalBuyer] = useState<User>(null);
  const [localSeller, setLocalSeller] = useState<User>(null);
  const [buyerSendingMethod, setBuyerSendingMethod] = useState<
    SendingMethod | ''
  >('');
  const [sellerSendingMethod, setSellerSendingMethod] = useState<
    SendingMethod | ''
  >('');

  const isBuyerSignerPresent = () =>
    signers.some((signer) => signer.role === 'buyer');

  const isSellerSignerPresent = () =>
    signers.some((signer) => signer.role === 'seller');

  const hasBuyerSigned = () =>
    signers.some((signer) => signer.role === 'buyer' && signer.signed);

  const hasSellerSigned = () =>
    signers.some((signer) => signer.role === 'seller' && signer.signed);

  useEffect(() => {
    if (isBuyerSignerPresent()) {
      setLocalBuyer(getRecipient(procedure.buyer));
    }
    if (!isRegistrationTramit(procedure) && isSellerSignerPresent()) {
      setLocalSeller(getRecipient(procedure.seller));
    }
  }, [signers, getRecipient, isRegistrationTramit, procedure]);

  const handleSend = async () => {
    const token = await getAccessTokenSilently();
    const signers = [];

    if (hasCommunicationMethod(localBuyer) && !hasBuyerSigned()) {
      signers.push({
        name: `${localBuyer.name} ${localBuyer.surname}`?.trim(),
        phone: localBuyer?.phone,
        email: localBuyer?.email,
        sendingMethod: buyerSendingMethod,
      });
    }

    if (
      !isRegistrationTramit(procedure) &&
      hasCommunicationMethod(localSeller) &&
      !hasSellerSigned()
    ) {
      signers.push({
        name: `${localSeller.name} ${localSeller.surname}`?.trim(),
        phone: localSeller?.phone,
        email: localSeller?.email,
        sendingMethod: sellerSendingMethod,
      });
    }

    await axios.post(
      `${process.env.REACT_APP_SUZUKI_URL}/documents/notify/${suzukiDocumentId}`,
      { signers },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    );

    hide();
  };

  const hasSelectedSendingMethods = () => {
    const isBuyerMethodSelected = buyerSendingMethod.length > 0;
    const isSellerMethodSelected = sellerSendingMethod.length > 0;

    if (isRegistrationTramit(procedure)) {
      return isBuyerMethodSelected;
    }
    if (!hasCommunicationMethod(localSeller) || hasSellerSigned()) {
      return isBuyerMethodSelected;
    }
    if (!hasCommunicationMethod(localBuyer) || hasBuyerSigned()) {
      return isSellerMethodSelected;
    }
    return isBuyerMethodSelected && isSellerMethodSelected;
  };

  return (
    <BaseModal
      isOpen={isShown}
      onClose={hide}
      width="min-w-[700px]"
      padding={false}
    >
      <section className="flex flex-col gap-9 h-full w-full p-9">
        <header className="flex flex-col gap-3 w-full">
          <h3 className="text-4xl font-bold text-zinc-700">
            Reenviar solicitud de firma
          </h3>
        </header>
        <section className="flex flex-col gap-6 w-full">
          {isBuyerSignerPresent() && (
            <RecipientItem
              recipientRole={AvailableRecipientRoles.BUYER}
              recipient={localBuyer}
              updateRecipient={setLocalBuyer}
              recipientSendingMethod={buyerSendingMethod}
              setRecipientSendingMethod={setBuyerSendingMethod}
              hasSigned={hasBuyerSigned()}
            />
          )}
          {!isRegistrationTramit(procedure) && isSellerSignerPresent() && (
            <RecipientItem
              recipientRole={AvailableRecipientRoles.SELLER}
              recipient={localSeller}
              updateRecipient={setLocalSeller}
              recipientSendingMethod={sellerSendingMethod}
              setRecipientSendingMethod={setSellerSendingMethod}
              hasSigned={hasSellerSigned()}
            />
          )}
        </section>
        <footer className="w-full flex justify-end gap-8">
          <button
            type="button"
            className="flex items-center gap-1.5 px-3 py-2 hover:bg-zinc-100 rounded-lg active:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-zinc-500"
            onClick={hide}
          >
            <MdClose className="size-5" />
            Cancelar
          </button>
          <button
            type="button"
            className="flex items-center gap-1.5 px-3 py-2 bg-blue-800 rounded-lg text-white disabled:opacity-50 disabled:cursor-not-allowed hover:bg-blue-900 active:bg-blue-950"
            onClick={handleSend}
            disabled={!hasSelectedSendingMethods()}
          >
            Enviar a firmar
            <MdSend className="size-5" />
          </button>
        </footer>
      </section>
    </BaseModal>
  );
}
