import React, { useContext } from 'react';
import { MdArrowForward, MdClose } from 'react-icons/md';

import { DigitallySignSteps } from '../DigitallySignStepsManager';
import { digitallySignContext } from '../../../../context/DigitallySignContext/DigitallySignContext';
import FileUploadIcon from '../../../../Modules/Documents/DocumentsTable/icons/FileUploadIcon';
import DocumentSignIcon from '../../../../Modules/Documents/DocumentsTable/icons/DocumentSignIcon';
import classNames from '../../../../helpers/classNames';
import DocumentSourceOption from './components/DocumentSourceOption';

type ChooseDocumentSourceStepProps = {
  hide: () => void;
};

export enum DocumentSourceOptions {
  UPLOAD_FROM_COMPUTER = 'UPLOAD_FROM_COMPUTER',
  USE_TEMPLATE = 'USE_TEMPLATE',
}

export default function ChooseDocumentSourceStep({
  hide,
}: Readonly<ChooseDocumentSourceStepProps>) {
  const [selectedOption, setSelectedOption] =
    React.useState<DocumentSourceOptions>();

  const { setCurrentDigitallySignStep, setChosenDocumentSource } =
    useContext(digitallySignContext);

  const handleNextStep = () => {
    setChosenDocumentSource(selectedOption);
    if (selectedOption === DocumentSourceOptions.USE_TEMPLATE) {
      setCurrentDigitallySignStep(DigitallySignSteps.CHOOSE_TEMPLATE);
      return;
    }
    setCurrentDigitallySignStep(DigitallySignSteps.UPLOAD_DOCUMENT);
  };

  const handleHideModal = () => {
    setSelectedOption(null);
    hide();
  };

  return (
    <section className="flex flex-col gap-14">
      <header>
        <h1 className="text-4xl text-zinc-700 font-bold">
          Firmar nuevo documento
        </h1>
      </header>
      <section role="radiogroup" className="flex gap-4">
        <DocumentSourceOption
          icon={<DocumentSignIcon className="size-14" />}
          title="Usar una plantilla"
          description="Genera un nuevo documento para que el cliente firme digitalmente."
          isChecked={selectedOption === DocumentSourceOptions.USE_TEMPLATE}
          onClick={() => setSelectedOption(DocumentSourceOptions.USE_TEMPLATE)}
        />
        <DocumentSourceOption
          icon={<FileUploadIcon className="size-14" />}
          title="Subir desde mi ordenador"
          description="Preferible cuando el documento ya está completado y solo requiere firma."
          isChecked={
            selectedOption === DocumentSourceOptions.UPLOAD_FROM_COMPUTER
          }
          onClick={() =>
            setSelectedOption(DocumentSourceOptions.UPLOAD_FROM_COMPUTER)
          }
        />
      </section>
      <footer className="w-full flex justify-end gap-8">
        <button
          type="button"
          onClick={handleHideModal}
          className="flex items-center gap-1.5 px-3 py-2 hover:bg-zinc-100 rounded-lg active:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-zinc-500"
        >
          <MdClose className="size-5" />
          Cancelar
        </button>
        <button
          type="button"
          disabled={!selectedOption}
          onClick={handleNextStep}
          className={classNames(
            'flex items-center gap-1.5 px-3 py-2 bg-blue-800 rounded-lg text-white disabled:opacity-50 disabled:cursor-not-allowed',
            selectedOption &&
              'hover:bg-blue-900 active:bg-blue-950 focus:outline-none focus:ring-2 focus:ring-blue-500',
          )}
        >
          Siguiente
          <MdArrowForward className="size-5" />
        </button>
      </footer>
    </section>
  );
}
