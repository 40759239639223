import React, { useContext } from 'react';

import ChooseDocumentSourceStep from './ChooseDocumentSourceStep/ChooseDocumentSourceStep';
import EditGeneratedDocumentStep from './EditGeneratedDocumentStep';
import { digitallySignContext } from '../../../context/DigitallySignContext/DigitallySignContext';
import ChooseTemplateStep from './ChooseTemplateStep/ChooseTemplateStep';
import {
  BateTramit,
  RegistrationTramit,
  Transfer,
  TemplateType,
} from '../../../types/types';
import ChooseRecipientsStep from './ChooseRecipientsStep/ChooseRecipientsStep';
import UploadDocumentStep from './UploadDocumentStep/UploadDocumentStep';

export enum DigitallySignSteps {
  CHOOSE_DOCUMENT_SOURCE = 1,
  CHOOSE_TEMPLATE = 2,
  EDIT_GENERATED_DOCUMENT = 3,
  CHOOSE_RECIPIENTS = 4,
  UPLOAD_DOCUMENT = 5,
}

export default function DigitallySignStepsManager({
  hide,
  procedure,
  templateType,
  forceUpdate,
}: {
  readonly hide: () => void;
  readonly procedure: Transfer | BateTramit | RegistrationTramit;
  readonly templateType: TemplateType;
  readonly forceUpdate: () => void;
}) {
  const { currentStep } = useContext(digitallySignContext);

  switch (currentStep) {
    case DigitallySignSteps.CHOOSE_DOCUMENT_SOURCE:
      return <ChooseDocumentSourceStep hide={hide} />;
    case DigitallySignSteps.CHOOSE_TEMPLATE:
      return (
        <ChooseTemplateStep procedure={procedure} templateType={templateType} />
      );
    case DigitallySignSteps.EDIT_GENERATED_DOCUMENT:
      return <EditGeneratedDocumentStep />;
    case DigitallySignSteps.CHOOSE_RECIPIENTS:
      return (
        <ChooseRecipientsStep
          procedure={procedure}
          hide={hide}
          templateType={templateType}
          forceUpdate={forceUpdate}
        />
      );
    case DigitallySignSteps.UPLOAD_DOCUMENT:
      return <UploadDocumentStep />;
    default:
      return null;
  }
}
