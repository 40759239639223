import React from 'react';

interface StepProps {
  number: number;
  text: string;
  isCurrentStep: boolean;
}

export default function DigitallySignStep({
  number,
  text,
  isCurrentStep,
}: Readonly<StepProps>) {
  if (isCurrentStep) {
    return (
      <li
        className="flex items-center gap-3 text-sm font-semibold text-zinc-800"
        aria-current="step"
      >
        <span className="text-center size-6 rounded-full border-2 border-blue-800">
          {number}
        </span>
        {text}
      </li>
    );
  }

  return (
    <li className="flex items-center gap-3 text-sm text-zinc-400">
      <span className="text-center size-6 rounded-full border border-zinc-400">
        {number}
      </span>
      {text}
    </li>
  );
}
