import React, { useContext } from 'react';
import { digitallySignContext } from '../../../context/DigitallySignContext/DigitallySignContext';
import { DigitallySignSteps } from '../steps/DigitallySignStepsManager';
import { DocumentSourceOptions } from '../steps/ChooseDocumentSourceStep/ChooseDocumentSourceStep';
import DigitallySignStep from './DigitallySignStep';

export default function DigitallySignStepper() {
  const { currentStep, chosenDocumentSource } =
    useContext(digitallySignContext);

  if (currentStep === DigitallySignSteps.CHOOSE_DOCUMENT_SOURCE) {
    return null;
  }

  if (chosenDocumentSource === DocumentSourceOptions.UPLOAD_FROM_COMPUTER) {
    return (
      <nav aria-label="Stepper">
        <ul className="flex justify-center items-center w-full gap-9">
          <DigitallySignStep
            number={1}
            text="Subir documento"
            isCurrentStep={currentStep === DigitallySignSteps.UPLOAD_DOCUMENT}
          />
          <DigitallySignStep
            number={2}
            text="Elegir destinatarios"
            isCurrentStep={currentStep === DigitallySignSteps.CHOOSE_RECIPIENTS}
          />
        </ul>
      </nav>
    );
  }

  return (
    <nav aria-label="Stepper">
      <ul className="flex justify-center items-center w-full gap-9">
        <DigitallySignStep
          number={1}
          text="Elegir plantilla"
          isCurrentStep={currentStep === DigitallySignSteps.CHOOSE_TEMPLATE}
        />
        <DigitallySignStep
          number={2}
          text="Editar documento"
          isCurrentStep={
            currentStep === DigitallySignSteps.EDIT_GENERATED_DOCUMENT
          }
        />
        <DigitallySignStep
          number={3}
          text="Elegir destinatarios"
          isCurrentStep={currentStep === DigitallySignSteps.CHOOSE_RECIPIENTS}
        />
      </ul>
    </nav>
  );
}
