import { useAuth0 } from '@auth0/auth0-react';
import FsLightbox from 'fslightbox-react';
import React, { useReducer, useEffect, useState, useContext } from 'react';

import Offer from './Offer/index';
import Issuance from './Issuance';
import { Information } from './Information';
import { Car, User } from '../../../types/types';
import {
  WeecoverOfferResponse,
  getMechanicalWarranty,
  MechanicalWarrantyInfoType,
} from '../../../services/mechanicalWarraties';
import Spinner from '../../../components/Spinner';
import useTypeform from '../../../hooks/useTypeform';
import { userInfoContext } from '../../../context/UserInfoContext/UserInfoContext';

type StepsForm = 'OFFER' | 'ISSUANCE';

type MechanicalWarrantyType = {
  car: Car;
  buyer: User;
};

export type OfferFormStateType = {
  carRegistration: string;
  carRegistrationDate: string;
  price: number;
  engineCapacity: number;
  kilometers: number;
};

export default function MechanicalWarranty({
  car,
  buyer,
}: MechanicalWarrantyType) {
  const [isLoading, setIsLoading] = useState(true);
  const [mechanicalWarranty, setMechanicalWarranty] =
    useState<MechanicalWarrantyInfoType>(null);
  const [step, setStep] = useState<StepsForm>('OFFER');
  const [selectedOffer, setSelectedOffer] =
    useState<WeecoverOfferResponse>(null);
  const [updater, forceUpdate] = useReducer((x) => x + 1, 0);
  const [showPreview, setShowPreview] = useState(false);

  const [offerFormState, setOfferFormState] = useState<OfferFormStateType>({
    carRegistration: car.plate || null,
    carRegistrationDate: car.enrollmentDate?.split('T')[0] || null,
    price: null,
    engineCapacity: Number(car.cc) || null,
    kilometers: Number(car.kms) || null,
  });

  const { getAccessTokenSilently } = useAuth0();
  const { accountInfo } = useContext(userInfoContext);

  useTypeform();

  const stepBack = () => {
    setSelectedOffer(null);
    setStep('OFFER');
  };

  const showDemoVideo = () => setShowPreview((prev) => !prev);

  useEffect(() => {
    const fetchMechanicalWarrranty = async () => {
      try {
        setIsLoading(true);
        const token = await getAccessTokenSilently();
        const response = await getMechanicalWarranty(token, car.id);
        setMechanicalWarranty(response);
      } finally {
        setIsLoading(false);
      }
    };
    fetchMechanicalWarrranty();
  }, [car.id, updater]);

  const hasNotMechanicalWarrantiesActivated =
    !accountInfo.mechanicalWarrantyProviders?.length;

  return (
    <>
      {hasNotMechanicalWarrantiesActivated && (
        <FsLightbox
          toggler={showPreview}
          sources={
            [
              <iframe
                title="Demo garantías mecánicas"
                src="https://www.tella.tv/video/cm4srfpig00060alc9cmhb8zb/embed?b=0&title=0&a=1&loop=0&t=0&muted=0&wt=0"
                width="1920px"
                height="1080px"
                allow="autoplay; fullscreen"
                allowFullScreen
              />,
            ] as unknown as string[]
          }
          types={[undefined]}
        />
      )}
      {hasNotMechanicalWarrantiesActivated && (
        <div className="bg-white shadow sm:rounded-lg mt-5">
          <div className="px-4 py-5 sm:p-6">
            <h3 className="text-base font-semibold leading-6 text-gray-900">
              Activar garantías mecánicas
            </h3>
            <div className="mt-2 sm:flex sm:items-start sm:justify-between">
              <div className="max-w-xl text-sm text-gray-500">
                <p>
                  Contrata garantías mecánicas desde el expediente. Hazlo
                  sencillo y sin perder tiempo.
                </p>
              </div>
              <div className="mt-5 sm:ml-6 sm:mt-0 sm:flex sm:flex-shrink-0 sm:items-center">
                <button
                  type="button"
                  onClick={showDemoVideo}
                  className="inline-flex items-center rounded-md bg-white px-3 py-2 text-sm text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                >
                  Ver demo
                </button>
                <button
                  type="button"
                  data-tf-popup="pmdt0Vt7"
                  data-tf-size="80"
                  data-tf-iframe-props="Garantías Mecánicas"
                  className="ml-4 inline-flex items-center rounded-md bg-blue-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-500"
                >
                  Activar
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
      {isLoading ? (
        <div className="overflow-hidden bg-white shadow sm:rounded-lg mt-5 flex justify-center items-center min-h-[300px]">
          <Spinner size={10} color="text-blue-500" marginTop={0} />
        </div>
      ) : (
        <div className="overflow-hidden bg-white shadow sm:rounded-lg mt-5">
          {mechanicalWarranty ? (
            <Information mechanicalWarranty={mechanicalWarranty} />
          ) : (
            <>
              {step === 'OFFER' && (
                <Offer
                  setStep={setStep}
                  selectedOffer={selectedOffer}
                  setOfferFormState={setOfferFormState}
                  offerFormState={offerFormState}
                  setSelectedOffer={setSelectedOffer}
                  hasNotMechanicalWarrantiesActivated={
                    hasNotMechanicalWarrantiesActivated
                  }
                />
              )}
              {step === 'ISSUANCE' && (
                <Issuance
                  car={car}
                  buyer={buyer}
                  stepBack={stepBack}
                  selectedOffer={selectedOffer}
                  offerFormState={offerFormState}
                  forceUpdate={forceUpdate}
                />
              )}
            </>
          )}
        </div>
      )}
    </>
  );
}
