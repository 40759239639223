import { fuelDictionary } from '../../../../../enums/fuelDictionary';
import { ccaaDictionary } from '../../../../../enums/ccaaDictionary';
import {
  parseDayMonthYear,
  parseDayMonthYearHourMinute,
} from '../../../../../helpers/parseDate';
import {
  BateTramit,
  Company,
  RegistrationTramit,
  Transfer,
  User,
} from '../../../../../types/types';

const isUser = (entity: User | Company): entity is User =>
  Object.prototype.hasOwnProperty.call(entity, 'dni');

export const generateTextReplacements = (
  tramit: BateTramit | RegistrationTramit | Transfer,
): { placeholder: string; value: string }[] =>
  [
    {
      placeholder: 'comprador_nombre',
      value: isUser(tramit.buyer) ? tramit?.buyer?.name : null,
    },
    {
      placeholder: 'comprador_apellidos',
      value: (tramit?.buyer as User)?.surname,
    },
    { placeholder: 'comprador_dni', value: (tramit?.buyer as User)?.dni },
    { placeholder: 'comprador_cif', value: (tramit?.buyer as Company)?.nif },
    {
      placeholder: 'comprador_razon_social',
      value: (tramit?.buyer as Company)?.legalName,
    },
    {
      placeholder: 'comprador_direccion',
      value: isUser(tramit.buyer)
        ? tramit.buyer?.address
        : tramit.buyer?.fiscalAddressAddress,
    },
    {
      placeholder: 'comprador_ciudad',
      value: isUser(tramit.buyer)
        ? tramit.buyer?.city
        : tramit.buyer?.fiscalAddressCity,
    },
    {
      placeholder: 'comprador_provincia',
      value: isUser(tramit.buyer)
        ? tramit.buyer?.province
        : tramit.buyer?.fiscalAddressProvince,
    },
    {
      placeholder: 'comprador_comunidad_autonoma',
      value: isUser(tramit.buyer)
        ? ccaaDictionary[tramit.buyer?.ccaa]
        : ccaaDictionary[tramit.buyer?.fiscalAddressState],
    },
    {
      placeholder: 'comprador_codigo_postal',
      value: isUser(tramit.buyer)
        ? tramit.buyer?.zipCode
        : tramit.buyer?.fiscalAddressZipCode,
    },
    {
      placeholder: 'comprador_telefono',
      value: (tramit?.buyer as User)?.phone,
    },
    { placeholder: 'comprador_email', value: (tramit?.buyer as User)?.email },
    {
      placeholder: 'comprador_representante_nombre',
      value: (tramit?.buyer as Company)?.representative?.name,
    },
    {
      placeholder: 'comprador_representante_apellidos',
      value: (tramit?.buyer as Company)?.representative?.surname,
    },
    {
      placeholder: 'comprador_representante_dni',
      value: (tramit?.buyer as Company)?.representative?.dni,
    },
    {
      placeholder: 'comprador_representante_direccion',
      value: (tramit?.buyer as Company)?.representative?.address,
    },
    {
      placeholder: 'comprador_representante_ciudad',
      value: (tramit?.buyer as Company)?.representative?.city,
    },
    {
      placeholder: 'comprador_representante_provincia',
      value: (tramit?.buyer as Company)?.representative?.province,
    },
    {
      placeholder: 'comprador_representante_comunidad_autonoma',
      value: ccaaDictionary[(tramit?.buyer as Company)?.representative?.ccaa],
    },
    {
      placeholder: 'comprador_representante_codigo_postal',
      value: (tramit?.buyer as Company)?.representative?.zipCode,
    },
    {
      placeholder: 'comprador_representante_telefono',
      value: (tramit?.buyer as Company)?.representative?.phone,
    },
    {
      placeholder: 'comprador_representante_email',
      value: (tramit?.buyer as Company)?.representative?.email,
    },

    {
      placeholder: 'vendedor_nombre',
      value:
        (tramit as Transfer)?.seller && isUser((tramit as Transfer)?.seller)
          ? ((tramit as Transfer)?.seller as User)?.name
          : null,
    },
    {
      placeholder: 'vendedor_apellidos',
      value: ((tramit as Transfer)?.seller as User)?.surname,
    },
    {
      placeholder: 'vendedor_dni',
      value: ((tramit as Transfer)?.seller as User)?.dni,
    },
    {
      placeholder: 'vendedor_cif',
      value: ((tramit as Transfer)?.seller as Company)?.nif,
    },
    {
      placeholder: 'vendedor_razon_social',
      value: ((tramit as Transfer)?.seller as Company)?.legalName,
    },
    {
      placeholder: 'vendedor_direccion',
      value:
        (tramit as Transfer)?.seller && isUser((tramit as Transfer).seller)
          ? ((tramit as Transfer).seller as User)?.address
          : ((tramit as Transfer).seller as Company)?.fiscalAddressAddress,
    },
    {
      placeholder: 'vendedor_ciudad',
      value:
        (tramit as Transfer)?.seller && isUser((tramit as Transfer).seller)
          ? ((tramit as Transfer).seller as User)?.city
          : ((tramit as Transfer).seller as Company)?.fiscalAddressCity,
    },
    {
      placeholder: 'vendedor_provincia',
      value:
        (tramit as Transfer)?.seller && isUser((tramit as Transfer).seller)
          ? ((tramit as Transfer).seller as User)?.province
          : ((tramit as Transfer).seller as Company)?.fiscalAddressProvince,
    },
    {
      placeholder: 'vendedor_comunidad_autonoma',
      value:
        (tramit as Transfer)?.seller && isUser((tramit as Transfer).seller)
          ? ccaaDictionary[((tramit as Transfer).seller as User)?.ccaa]
          : ccaaDictionary[
              ((tramit as Transfer).seller as Company)?.fiscalAddressState
            ],
    },
    {
      placeholder: 'vendedor_codigo_postal',
      value:
        (tramit as Transfer)?.seller && isUser((tramit as Transfer).seller)
          ? ((tramit as Transfer).seller as User)?.zipCode
          : ((tramit as Transfer).seller as Company)?.fiscalAddressZipCode,
    },
    {
      placeholder: 'vendedor_telefono',
      value: ((tramit as Transfer)?.seller as User)?.phone,
    },
    {
      placeholder: 'vendedor_email',
      value: ((tramit as Transfer)?.seller as User)?.email,
    },
    {
      placeholder: 'vendedor_representante_nombre',
      value: ((tramit as Transfer)?.seller as Company)?.representative?.name,
    },
    {
      placeholder: 'vendedor_representante_apellidos',
      value: ((tramit as Transfer)?.seller as Company)?.representative?.surname,
    },
    {
      placeholder: 'vendedor_representante_dni',
      value: ((tramit as Transfer)?.seller as Company)?.representative?.dni,
    },
    {
      placeholder: 'vendedor_representante_direccion',
      value: ((tramit as Transfer)?.seller as Company)?.representative?.address,
    },
    {
      placeholder: 'vendedor_representante_ciudad',
      value: ((tramit as Transfer)?.seller as Company)?.representative?.city,
    },
    {
      placeholder: 'vendedor_representante_provincia',
      value: ((tramit as Transfer)?.seller as Company)?.representative
        ?.province,
    },
    {
      placeholder: 'vendedor_representante_comunidad_autonoma',
      value:
        ccaaDictionary[
          ((tramit as Transfer)?.seller as Company)?.representative?.ccaa
        ],
    },
    {
      placeholder: 'vendedor_representante_codigo_postal',
      value: ((tramit as Transfer)?.seller as Company)?.representative?.zipCode,
    },
    {
      placeholder: 'vendedor_representante_telefono',
      value: ((tramit as Transfer)?.seller as Company)?.representative?.phone,
    },
    {
      placeholder: 'vendedor_representante_email',
      value: ((tramit as Transfer)?.seller as Company)?.representative?.email,
    },

    { placeholder: 'vehiculo_marca', value: tramit?.car?.brand },
    { placeholder: 'vehiculo_modelo', value: tramit?.car?.model },
    { placeholder: 'vehiculo_matrícula', value: tramit?.car?.plate },
    {
      placeholder: 'vehiculo_bastidor',
      value: tramit?.car?.frameNumber,
    },
    {
      placeholder: 'vehiculo_fecha_matriculacion',
      value: tramit?.car?.enrollmentDate
        ? parseDayMonthYear(new Date(tramit?.car?.enrollmentDate))
        : null,
    },
    {
      placeholder: 'vehiculo_combustible',
      value: fuelDictionary[tramit?.car?.fuel],
    },
    { placeholder: 'vehiculo_kilometros', value: tramit?.car?.kms?.toString() },
    { placeholder: 'vehiculo_cubicaje', value: tramit?.car?.cc },
    { placeholder: 'vehiculo_potencia_fiscal', value: tramit?.car?.fiscalCV },
    { placeholder: 'vehiculo_potencia_kw', value: tramit?.car?.powerKw },
    {
      placeholder: 'vehiculo_precio',
      value: (tramit as Transfer)?.transaction?.priceContractValue?.toString(),
    },
    {
      placeholder: 'fecha_contrato',
      value: parseDayMonthYearHourMinute(new Date()),
    },
    {
      placeholder: 'gestor_nombre',
      value: tramit?.agent?.name,
    },
    {
      placeholder: 'gestor_apellidos',
      value: tramit?.agent?.surname,
    },
    {
      placeholder: 'gestor_num_colegiado',
      value: tramit?.agent?.colNumber,
    },
    {
      placeholder: 'gestor_nif',
      value: tramit?.agent?.nif,
    },
    {
      placeholder: 'gestor_ubicacion_colegio',
      value: tramit?.agent?.colLocation,
    },
    {
      placeholder: 'gestor_direccion',
      value: tramit?.agent?.address,
    },
    {
      placeholder: 'gestor_numero_direccion',
      value: tramit?.agent?.addressNumber,
    },
    {
      placeholder: 'gestor_ciudad',
      value: tramit?.agent?.city,
    },
    {
      placeholder: 'gestor_provincia',
      value: tramit?.agent?.colLocation,
    },
    {
      placeholder: 'gestor_codigo_postal',
      value: tramit?.agent?.zipCode,
    },
  ]
    .map((i) => ({ ...i, placeholder: `{{${i.placeholder}}}` }))
    .map((i) => (i.value ? i : { ...i, value: '' }));
