import React from 'react';
import BaseModal from '../../components/BaseModal';
import { DigitallySignContextProvider } from '../../context/DigitallySignContext/DigitallySignContext';
import DigitallySignStepsManager from './steps/DigitallySignStepsManager';
import {
  Transfer,
  BateTramit,
  RegistrationTramit,
  TemplateType,
} from '../../types/types';
import DigitallySignStepper from './components/DigitallySignStepper';

type DigitallySignModalProps = {
  isShown: boolean;
  hide: () => void;
  readonly templateType: TemplateType;
  readonly procedure: Transfer | BateTramit | RegistrationTramit;
  forceUpdate: () => void;
};

export default function DigitallySignModal({
  procedure,
  templateType,
  isShown,
  hide,
  forceUpdate,
}: Readonly<DigitallySignModalProps>) {
  return (
    <BaseModal
      isOpen={isShown}
      onClose={hide}
      width="w-contain"
      padding={false}
    >
      <section className="flex flex-col gap-9 h-full w-full p-9">
        <DigitallySignContextProvider>
          <DigitallySignStepper />
          <DigitallySignStepsManager
            hide={hide}
            procedure={procedure}
            templateType={templateType}
            forceUpdate={forceUpdate}
          />
        </DigitallySignContextProvider>
      </section>
    </BaseModal>
  );
}
