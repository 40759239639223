import React from 'react';
import { validate as validateUUID } from 'uuid';
import {
  BanIcon,
  BellIcon,
  DownloadIcon,
  GlobeAltIcon,
} from '@heroicons/react/outline';
import axios from 'axios';

const viewExtraAction = {
  value: 'Original',
  action: async (params) => {
    const token = await params.getToken();
    const buffer = await axios
      .get(
        `${process.env.REACT_APP_SUZUKI_URL}/documents/download/${params.serviceId}?original=true`,
        {
          responseType: 'arraybuffer',
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      )
      .then((res) => res.data);
    const blob = new Blob([buffer], { type: 'application/pdf' });
    const blobURL = URL.createObjectURL(blob);
    window.open(blobURL);
  },
  icon: <DownloadIcon className="h-4" />,
};

const cancelExtraAction = {
  value: 'Cancelar',
  action: async (params) => {
    params.setDocumentIdForDelete(params.documentId);
    params.setOpenDeleteModal(true);
  },
  icon: <BanIcon className="h-4" />,
};

const goToPlatformExtraAction = {
  value: 'Ver en Plataforma',
  action: async (params) => {
    const a = document.createElement('a');

    if (validateUUID(params.serviceId)) {
      a.href = `https://${
        process.env.REACT_APP_ENV !== 'PROD' ? 'apps-d' : 'apps'
      }.docusign.com/send/documents/details/${params.serviceId}`;
    } else {
      a.href = `https://${
        process.env.REACT_APP_ENV !== 'PROD' ? 'sandbox' : 'app'
      }.docuten.com/ViewDocumentByAdmin.html?id=${params.serviceId}`;
    }

    a.target = '_blank';
    a.click();
  },
  icon: <GlobeAltIcon className="h-4" />,
};

const notifyExtraAction = {
  value: 'Notificar',
  action: async (params) => {
    const token = await params.getToken();
    await axios.post(
      `${process.env.REACT_APP_SUZUKI_URL}/documents/notify/${params.documentId}`,
      {},
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    );
  },
  icon: <BellIcon className="h-4" />,
};

const downloadSignedExtraAction = {
  value: 'Descargar firmado',
  action: async (params) => {
    const token = await params.getToken();
    const buffer = await axios
      .get(
        `${process.env.REACT_APP_SUZUKI_URL}/documents/download/${params.serviceId}?original=false`,
        {
          responseType: 'arraybuffer',
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      )
      .then((res) => res.data);
    const blob = new Blob([buffer], { type: 'application/pdf' });
    const blobURL = URL.createObjectURL(blob);
    window.open(blobURL);
  },
  icon: <DownloadIcon className="h-4" />,
};

export const extraActionsDictionary = new Map([
  [
    'PENDING',
    [
      notifyExtraAction,
      viewExtraAction,
      cancelExtraAction,
      goToPlatformExtraAction,
    ],
  ],
  [
    'COMPLETED',
    [downloadSignedExtraAction, cancelExtraAction, goToPlatformExtraAction],
  ],
  ['CANCELLED', [viewExtraAction, goToPlatformExtraAction]],
]);
