import React, { Dispatch, SetStateAction } from 'react';
import classNames from '../../../../../helpers/classNames';
import { RecipientRolesTabGroupOptions } from './RecipientsTabGroup';

interface RecipientsTabGroupItemProps {
  selectedRecipient: RecipientRolesTabGroupOptions;
  setSelectedRecipient: Dispatch<SetStateAction<RecipientRolesTabGroupOptions>>;
  recipient: RecipientRolesTabGroupOptions;
}

export default function RecipientsTabGroupItem({
  selectedRecipient,
  setSelectedRecipient,
  recipient,
}: Readonly<RecipientsTabGroupItemProps>) {
  return (
    <button
      role="option"
      type="button"
      className={classNames(
        'flex items-center justify-center px-5 py-3 transition rounded-3xl hover:bg-zinc-100 hover:text-zinc-600',
        selectedRecipient === recipient &&
          'bg-blue-50 text-blue-800 pointer-events-none',
      )}
      aria-selected={selectedRecipient === recipient}
      onClick={() => setSelectedRecipient(recipient)}
    >
      {recipient}
    </button>
  );
}
