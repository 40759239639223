import { useParams } from 'react-router-dom';
import { useState, useContext } from 'react';
import { bateDetailsContext } from '../../../context/bate-details.context';
import EditorModal from '../../../components/EditorModal';
import TopSection from './components/TopSection';
import BateSummary from './components/BateSummary';
import { useServices } from '../../../services';
import DocumentsSection from './components/Documents/DocumentsSection';
import BateStatusSelector from './components/BateStatusSelector';
import AgentAssignBox from '../../../components/AgentAssignBox';
import Timeline from '../../../components/Timeline/Timeline';
import SignatureDetails from '../../../components/SignatureDetails/SignatureDetails';
import { ENTITY_TYPES } from '../../../helpers/enums';
import RelatedTramit from './components/RelatedTramit';
import CommentsModule from '../../../components/Comments/CommentsModule';

const BATE_DOCUMENTS = [
  {
    title: 'Solicitud de baja',
    key: 'temporaryLeaveRequest',
  },
  { title: 'Contrato de compraventa', key: 'saleContract' },
  { title: 'Factura profesional', key: 'proInvoice' },
  {
    title: 'Modelo 620',
    key: 'paidItpFile',
  },
];

export default function BateDetails() {
  const [modalType, setModalType] = useState(null);

  const { code } = useParams();

  const { aggregatedBate, bateLoading, forceUpdate, bateIncidents } =
    useContext(bateDetailsContext);

  const { bate, car, agent, buyer } = aggregatedBate || {};

  const { editBate } = useServices();

  if (bateLoading) return null;

  return (
    <div className="min-h-full">
      <EditorModal
        type={modalType}
        setType={setModalType}
        id={code}
        save={forceUpdate}
        plate={car?.plate}
        preloadedState={aggregatedBate}
        tramit={bate}
        editTramit={editBate}
      />

      <main>
        <div className="mt-8 max-w-3xl mx-auto grid grid-cols-1 gap-6 sm:px-6 lg:max-w-7xl lg:grid-flow-col-dense lg:grid-cols-3 relative">
          <div className="space-y-6 lg:col-start-1 lg:col-span-2">
            <section>
              <div className="bg-white shadow sm:rounded-lg">
                <TopSection />
                <RelatedTramit bateId={bate.id} />
                <div className="border-t border-gray-200 px-4 py-5 sm:px-6">
                  <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
                    <BateSummary setModalType={setModalType} />
                    <DocumentsSection
                      documents={BATE_DOCUMENTS}
                      tramit={bate}
                      tramitCode={bate?.bateCode}
                      editTramit={editBate}
                      forceUpdate={forceUpdate}
                      setModalType={setModalType}
                    />
                  </dl>
                </div>
              </div>
            </section>
            <SignatureDetails
              tramitType={ENTITY_TYPES.BATE}
              tramitCode={bate?.bateCode}
              tramit={aggregatedBate}
            />
            <CommentsModule
              entityId={bate?.id}
              entityIncidents={bateIncidents}
              showNotificationOption
              tramitCode={bate?.bateCode}
              tramitType="BATE"
              emailToNotify={buyer?.representative?.email}
              platform={bate?.host}
            />
          </div>
          <section
            aria-labelledby="timeline-title"
            className="lg:col-start-3 lg:col-span-1"
          >
            <BateStatusSelector />
            <AgentAssignBox
              agent={agent}
              tramitCode={bate?.bateCode}
              editTramit={editBate}
              forceUpdate={forceUpdate}
            />
            <Timeline tramitId={bate?.id} />
          </section>
        </div>
      </main>
    </div>
  );
}
