import { Link } from 'react-router-dom';
import React, { useState, useEffect, useCallback } from 'react';
import { CalendarIcon, FolderIcon } from '@heroicons/react/solid';

import { parseDate } from '../helpers';
import { statusEnums } from '../helpers/enums';

const getTramitPathString = (tramitType) => {
  switch (tramitType) {
    case 'batecom_purchase':
      return 'bates';
    case 'batecom_sale':
    case 'transfer':
      return 'transfers';
    case 'registration':
      return 'registration';
    default:
      return 'unknown';
  }
};

const getTramitTypeLabel = (tramitType) => {
  switch (tramitType) {
    case 'batecom_purchase':
      return 'BATE';
    case 'batecom_sale':
      return 'COM';
    case 'transfer':
      return 'Transferencia';
    case 'registration':
      return 'Matriculación';
    default:
      return 'unknown';
  }
};

function AssociatedTramits({ entityId, entityIdentifier, fetchFunction }) {
  const [tramitsList, setTramitsList] = useState([]);
  const [page, setPage] = useState(0);

  const fetchUserTransactions = useCallback(async () => {
    const response = await fetchFunction(entityId, entityIdentifier, page);
    setTramitsList(response.tramits);
  }, [fetchFunction, entityIdentifier, page]);

  useEffect(() => {
    fetchUserTransactions();
  }, [fetchUserTransactions, page]);

  const handleNextPage = () => {
    setPage(page + 1);
  };

  const handlePreviousPage = () => {
    setPage(page - 1);
  };

  return (
    <>
      <h3 className="text-lg leading-6 font-medium text-gray-900 mt-4">
        Trámites asociados:
      </h3>
      <div className="bg-white shadow overflow-hidden sm:rounded-md mt-5">
        <ul className="divide-y divide-gray-200">
          {Boolean(tramitsList.length) &&
            tramitsList.map((tramit) => (
              <li key={tramit.tramitCode}>
                <Link
                  to={`/${getTramitPathString(tramit.tramitType)}/${
                    tramit.tramitCode
                  }`}
                >
                  <div className="px-4 py-4 sm:px-6">
                    <div className="flex items-center justify-between">
                      <p className="text-sm font-medium text-indigo-600 truncate">
                        {tramit.tramitCode} - {tramit.plate}
                      </p>
                      <div className="ml-2 shrink-0 flex">
                        <p
                          className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full ${
                            statusEnums.find(
                              (status) => status.id === tramit.status,
                            )?.bgColor
                          } ${
                            statusEnums.find(
                              (status) => status.id === tramit.status,
                            )?.textColor
                          } mr-1`}
                        >
                          {
                            statusEnums.find(
                              (status) => status.id === tramit.status,
                            )?.label
                          }
                        </p>
                        {tramit.hasIncident && (
                          <p className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-red-100 text-red-800 mr-1">
                            Incidencia
                          </p>
                        )}
                        {tramit.isInactive && (
                          <p className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-gray-400 text-black">
                            Inactiva
                          </p>
                        )}
                      </div>
                    </div>
                    <div className="mt-2 sm:flex sm:justify-between">
                      <div className="sm:flex gap-x-4">
                        <span className="flex items-center text-sm text-gray-500">
                          <FolderIcon
                            className="shrink-0 mr-1.5 h-5 w-5 text-gray-400"
                            aria-hidden="true"
                          />
                          {getTramitTypeLabel(tramit.tramitType)}
                        </span>
                      </div>
                      <div className="mt-2 flex items-center text-sm text-gray-500 sm:mt-0">
                        <CalendarIcon
                          className="shrink-0 mr-1.5 h-5 w-5 text-gray-400"
                          aria-hidden="true"
                        />
                        <p>
                          <time dateTime={parseDate(tramit.createdAt)}>
                            {parseDate(tramit.createdAt)}
                          </time>
                        </p>
                      </div>
                    </div>
                  </div>
                </Link>
              </li>
            ))}
        </ul>
        {(page > 0 || tramitsList.length === 10) && (
          <div className="flex items-center justify-center gap-3 my-3">
            {page > 0 && (
              <button
                type="button"
                className="text-md px-5 py-2 border rounded-md hover:bg-gray-100 hover:cursor-pointer"
                onClick={handlePreviousPage}
              >
                Anterior
              </button>
            )}
            {tramitsList.length === 10 && (
              <button
                type="button"
                className="text-md px-5 py-2 border rounded-md hover:bg-gray-100 hover:cursor-pointer"
                onClick={handleNextPage}
              >
                Siguiente
              </button>
            )}
          </div>
        )}
      </div>
    </>
  );
}

export default AssociatedTramits;
