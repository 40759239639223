import React from 'react';
import { PaperClipIcon } from '@heroicons/react/outline';

import { RowData } from '../index';
import TableActions from './TableActions';
import {
  FILE_TYPE,
  Transfer,
  BateTramit,
  RegistrationTramit,
} from '../../../types/types';

function TableBody({
  tramit,
  tramitType,
  templateRows,
  forceUpdate,
}: Readonly<{
  tramit: Transfer | BateTramit | RegistrationTramit;
  tramitType: 'transaction' | 'bate' | 'registration';
  templateRows: RowData[];
  forceUpdate: () => void;
}>) {
  const withExtraFiles = (rows: RowData[]): RowData[] => {
    let owner:
      | Transfer['transaction']
      | BateTramit['bate']
      | RegistrationTramit['registration'];
    let extraFiles: string[] = [];
    switch (tramitType) {
      case 'transaction':
        owner = (tramit as Transfer).transaction;
        extraFiles = (tramit as Transfer).transaction?.extraFiles;
        break;
      case 'bate':
        owner = (tramit as BateTramit).bate;
        extraFiles = (tramit as BateTramit).bate?.extraFiles;
        break;
      case 'registration':
        owner = (tramit as RegistrationTramit).registration;
        extraFiles = (tramit as RegistrationTramit).registration?.extraFiles;
        break;
      default:
        owner = null;
        extraFiles = [];
    }
    return [
      ...rows,
      ...(extraFiles
        ? extraFiles.map((file) => ({
            icon: <PaperClipIcon className="w-5 h-5" />,
            label: file?.split('-hash-')[0] || 'Documento extra',
            docURI: file,
            fileType: FILE_TYPE.EXTRA_FILES,
            owner,
          }))
        : []),
    ];
  };

  const filePrefix = tramit.car?.plate || '';

  return (
    <>
      {withExtraFiles(templateRows).map((row) => (
        <tr key={`${row.fileType}-${row.owner?.id}`}>
          <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
            <div className="flex justify-start items-center">
              <span className="mr-2">{row.icon}</span>
              {row.label}
              {row.signatureStatus &&
                (row.signatureStatus === 'PENDING' ? (
                  <span className="inline-flex items-center rounded-full bg-yellow-50 px-2 py-1 ml-2 text-xs font-medium text-yellow-800 ring-1 ring-inset ring-yellow-600/20">
                    Pendiente de firmar
                  </span>
                ) : (
                  <span className="inline-flex items-center rounded-full bg-green-50 px-2 py-1 ml-2 text-xs font-medium text-green-700 ring-1 ring-inset ring-green-600/20">
                    Firmado
                  </span>
                ))}
            </div>
          </td>
          <TableActions
            fileType={row.fileType}
            uri={row.docURI}
            forceUpdate={forceUpdate}
            actionAppliedTo={row.owner}
            tramit={tramit}
            tramitType={tramitType}
            fileName={filePrefix ? `${filePrefix} - ${row.label}` : row.label}
            canUseDigitalSignature={row.canUseDigitalSignature}
            suzukiDocumentId={row.suzukiDocumentId}
            signatureStatus={row.signatureStatus}
            signers={row.signers}
            hideAttachementButton={row.hideAttachementButton}
          />
        </tr>
      ))}
    </>
  );
}

export default TableBody;
