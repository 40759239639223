import React, { useEffect, useState } from 'react';

import classNames from '../../../../../helpers/classNames';

type Props = Readonly<{
  selectedTemplateId: string;
}>;

export default function TemplatePreview({ selectedTemplateId }: Props) {
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    setLoading(true);
  }, [selectedTemplateId]);

  return (
    <aside className="relative w-[600px] h-full rounded-xl overflow-hidden">
      {loading && (
        <article className="w-full h-full bg-zinc-200 rounded-lg text-transparent animate-pulse" />
      )}
      <iframe
        title="preview"
        className={classNames('block aspect-auto', loading && 'invisible')}
        width="100%"
        height="100%"
        src={`https://docs.google.com/document/d/${selectedTemplateId}/preview`}
        onLoad={() => setLoading(false)}
      />
    </aside>
  );
}
