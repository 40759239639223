import React, { useState } from 'react';
import { XIcon } from '@heroicons/react/solid';
import { useFeatureFlagEnabled } from 'posthog-js/react';

export default function Banner() {
  const [showBanner, setShowBanner] = useState(true);

  const dgtDown = useFeatureFlagEnabled('banner-dgt-down');
  const cataloniaNationalDay = useFeatureFlagEnabled(
    'banner-catalonia-national-day',
  );
  const christmasDays = useFeatureFlagEnabled('banner-christmas-days');

  const closeBanner = () => {
    setShowBanner(false);
  };

  const christmasDaysBanner = (
    <div className="pointer-events-none fixed inset-x-0 bottom-0 sm:px-6 sm:pb-5 lg:px-8">
      <div className="pointer-events-auto flex items-center justify-between gap-x-6 bg-yellow-100 px-6 py-2.5 sm:rounded-xl sm:py-3 sm:pl-4 sm:pr-3.5">
        <p className="text-sm/6 text-yellow-700 justify-center">
          Los días 24, 26 y 31 de diciembre DGT no permitirá a las gestorías
          presentar ni finalizar trámites.
        </p>
        <button
          type="button"
          className="-m-3 flex-none p-3 focus-visible:outline-offset-[-4px]"
          onClick={closeBanner}
        >
          <XIcon aria-hidden="true" className="size-5 text-yellow-700" />
        </button>
      </div>
    </div>
  );

  const cataloniaNationalDayBanner = (
    <div className="pointer-events-none fixed inset-x-0 bottom-0 sm:px-6 sm:pb-5 lg:px-8">
      <div className="pointer-events-auto flex items-center justify-between gap-x-6 bg-yellow-100 px-6 py-2.5 sm:rounded-xl sm:py-3 sm:pl-4 sm:pr-3.5">
        <p className="text-sm/6 text-yellow-700 justify-center">
          El día 11 de Septiembre DGT no permitirá a las gestorías presentar ni
          finalizar trámites.
        </p>
        <button
          type="button"
          className="-m-3 flex-none p-3 focus-visible:outline-offset-[-4px]"
          onClick={closeBanner}
        >
          <XIcon aria-hidden="true" className="size-5 text-yellow-700" />
        </button>
      </div>
    </div>
  );

  const dgtDownBanner = (
    <div className="pointer-events-none fixed inset-x-0 bottom-0 sm:px-6 sm:pb-5 lg:px-8">
      <div className="pointer-events-auto flex items-center justify-between gap-x-6 bg-red-100 px-6 py-2.5 sm:rounded-xl sm:py-3 sm:pl-4 sm:pr-3.5">
        <p className="text-sm/6 text-red-700">
          En estos momentos DGT está temporalmente fuera de servicio. Esto puede
          ocasionar retrasos en la finalización de los trámites, la gestión será
          completada tan pronto como la DGT restablezca su funcionamiento.
        </p>
        <button
          type="button"
          className="-m-3 flex-none p-3 focus-visible:outline-offset-[-4px]"
        >
          <XIcon aria-hidden="true" className="size-5 text-red-700" />
        </button>
      </div>
    </div>
  );

  if (!showBanner) return null;

  return (
    <>
      {christmasDays && christmasDaysBanner}
      {cataloniaNationalDay && cataloniaNationalDayBanner}
      {dgtDown && dgtDownBanner}
    </>
  );
}
