import React from 'react';
import PersonArrowRightIcon from '../icons/PersonArrowRightIcon';
import { AvailableRecipientRoles } from './RecipientItem';
import PersonArrowLeftIcon from '../icons/PersonArrowLeftIcon';
import classNames from '../../../../../helpers/classNames';

interface RecipientItemIconProps {
  role: AvailableRecipientRoles;
  isDisabled?: boolean;
}

export default function RecipientItemIcon({
  role,
  isDisabled,
}: Readonly<RecipientItemIconProps>) {
  if (role === AvailableRecipientRoles.BUYER) {
    return (
      <figure
        className={classNames(
          'flex flex-col justify-center items-center gap-1.5 rounded-2xl size-28 p-4 font-bold text-sm',
          isDisabled ? 'bg-zinc-100' : 'bg-green-100 text-green-800',
        )}
      >
        <PersonArrowLeftIcon className="size-11" />
        <figcaption>Comprador</figcaption>
      </figure>
    );
  }

  return (
    <figure
      className={classNames(
        'flex flex-col justify-center items-center gap-1.5 rounded-2xl size-28 p-4 font-bold text-sm',
        isDisabled ? 'bg-zinc-100' : 'bg-green-100 text-green-800',
      )}
    >
      <PersonArrowRightIcon className="size-11" />
      <figcaption>Vendedor</figcaption>
    </figure>
  );
}
