import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useReducer,
  useState,
} from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import {
  DocumentTextIcon,
  IdentificationIcon,
  OfficeBuildingIcon,
} from '@heroicons/react/outline';
import { AiOutlineCar } from 'react-icons/ai';

import {
  Bate,
  BateTramit,
  Car,
  Company,
  DigitalSignature,
  FILE_TYPE,
  RegistrationTramit,
  Signer,
  Transaction,
  Transfer,
  User,
} from '../../types/types';
import TableBody from './DocumentsTable/TableBody';
import AgencyDocumentsTable from './DocumentsTable/Agency';
import DragAndDropFile from './DragAndDropFile/DragAndDropFile';
import {
  loadDigitalSignaturesByProcedure,
  loadInvoice,
} from '../../services/services';
import { userInfoContext } from '../../context/UserInfoContext/UserInfoContext';

type DocumentsProps = {
  tramit: Transfer | BateTramit | RegistrationTramit;
  entityType: 'transaction' | 'bate' | 'registration';
  forceUpdate: () => void;
};

export type RowData = {
  icon: React.ReactElement;
  label: string;
  docURI: string;
  fileType: FILE_TYPE | null;
  owner: Company | User | Transaction | Car | Bate | null;
  canUseDigitalSignature?: boolean;
  signatureStatus?: 'PENDING' | 'FINISHED' | 'CANCELLED';
  signers?: Signer[];
  suzukiDocumentId?: string;
  hideAttachementButton?: boolean;
};

function Documents({
  tramit,
  entityType,
  forceUpdate,
}: Readonly<DocumentsProps>) {
  const [invoiceUri, setInvoiceUri] = useState<string | null>(null);
  const [saleContract, setSaleContract] = useState<DigitalSignature | null>(
    null,
  );
  const [missplacement, setMissplacement] = useState<DigitalSignature | null>(
    null,
  );
  const [otherDocs, setOtherDocs] = useState<DigitalSignature[]>([]);
  const [mandates, setMandates] = useState<DigitalSignature[]>([]);
  const [updater, forceUpdateDocs] = useReducer((x) => x + 1, 0);
  const { getAccessTokenSilently } = useAuth0();

  const { companyInfo } = useContext(userInfoContext);

  const getProcedureCode = (): string => {
    switch (entityType) {
      case 'transaction':
        return (tramit as Transfer).transaction.transactionCode;
      case 'bate':
        return (tramit as BateTramit).bate.bateCode;
      case 'registration':
        return (tramit as RegistrationTramit).registration.registrationCode;
      default:
        return null;
    }
  };

  const getTramitId = (): string => {
    switch (entityType) {
      case 'transaction':
        return (tramit as Transfer).transaction.id;
      case 'bate':
        return (tramit as BateTramit).bate.id;
      case 'registration':
        return (tramit as RegistrationTramit).registration.id;
      default:
        return null;
    }
  };

  const handleUpdateData = () => {
    forceUpdate();
    forceUpdateDocs();
  };

  useEffect(() => {
    const fetchInvoice = async () => {
      const token = await getAccessTokenSilently();
      const holdedInvoiceUri = await loadInvoice(
        token,
        (tramit as Transfer).transaction?.holdedInvoiceId,
      );
      setInvoiceUri(holdedInvoiceUri);
    };

    const fetchDigitalSignatures = async () => {
      const token = await getAccessTokenSilently();
      const fetchedDocuments = await loadDigitalSignaturesByProcedure(
        token,
        entityType,
        getProcedureCode(),
      );

      const oldContracts = fetchedDocuments?.find(
        (doc) => doc?.parts?.[0] === 'contract' && doc.status !== 'CANCELLED',
      );
      const newContracts = fetchedDocuments?.find(
        (doc) =>
          doc?.metadata?.type === 'Contrato' && doc.status !== 'CANCELLED',
      );
      const fetchedSaleContract = oldContracts ?? newContracts;

      const oldMandates = fetchedDocuments?.filter(
        (doc) => doc?.parts?.[0] === 'mandate' && doc.status !== 'CANCELLED',
      );
      const newMandates = fetchedDocuments?.filter(
        (doc) =>
          doc?.metadata?.type === 'Mandato' && doc.status !== 'CANCELLED',
      );
      const fetchedMandates = oldMandates?.length ? oldMandates : newMandates;

      const fetchedMissplacement = fetchedDocuments?.find(
        (doc) =>
          doc?.metadata?.type === 'Extravío' && doc.status !== 'CANCELLED',
      );

      const newOtherDocs = fetchedDocuments?.filter(
        (doc) => doc?.metadata?.type === 'Otros' && doc.status !== 'CANCELLED',
      );

      const oldCustomDocs = fetchedDocuments?.filter(
        (doc) =>
          doc?.parts?.[0] === 'custom' &&
          doc.status !== 'CANCELLED' &&
          !doc.metadata.type,
      );

      const fetchedOtherDocs = oldCustomDocs?.length
        ? oldCustomDocs
        : newOtherDocs;

      setOtherDocs(fetchedOtherDocs);
      setSaleContract(fetchedSaleContract);
      setMandates(fetchedMandates);
      setMissplacement(fetchedMissplacement);
    };

    if (!tramit) return;

    if (
      entityType === 'transaction' &&
      (tramit as Transfer).transaction?.holdedInvoiceId
    ) {
      fetchInvoice();
    }
    fetchDigitalSignatures();
  }, [updater]);

  const agencyDocumentsRows = useMemo(() => {
    switch (entityType) {
      case 'bate':
        return [
          {
            label: 'Solicitud de baja por entrega a compraventa',
            docURI: (tramit as BateTramit).bate?.temporaryLeaveRequest,
            icon: <DocumentTextIcon className="w-5 h-5" />,
          },
        ];
      case 'transaction':
        return [
          {
            label: 'Permiso provisional de gestoría',
            docURI: (tramit as Transfer).transaction?.temporaryCirculationDoc,
            icon: <DocumentTextIcon className="w-5 h-5" />,
          },
          {
            label: 'Permiso provisional de DGT',
            docURI: (tramit as Transfer).transaction?.presentationProof,
            icon: <DocumentTextIcon className="w-5 h-5" />,
          },
          {
            label: 'Factura de gestoría',
            docURI: invoiceUri,
            icon: <DocumentTextIcon className="w-5 h-5" />,
          },
        ];
      case 'registration':
        // Ficha técnica provisional
        // Permiso de circulación provisional
        return [];
      default:
        return [];
    }
  }, [entityType, tramit, invoiceUri]);

  const generateTemplateRows = (): RowData[] => {
    if (entityType === 'bate') {
      const { seller, car, bate } = tramit as BateTramit;
      const [mandate] = mandates;
      return (seller as Company)?.representative
        ? [
            {
              label: 'Tarjeta Identificación Fiscal - Vendedor',
              docURI: (seller as Company)?.nifFile,
              icon: <OfficeBuildingIcon className="w-5 h-5" />,
              owner: seller,
              fileType: FILE_TYPE.TIF,
            },
            {
              label: 'DNI Frontal - Representante Vendedor',
              docURI: (seller as Company)?.representative?.photoDni?.[1],
              icon: <IdentificationIcon className="w-5 h-5" />,
              owner: (seller as Company)?.representative,
              fileType: FILE_TYPE.DOI_FRONT,
            },
            {
              label: 'DNI Reverso - Representante Vendedor',
              docURI: (seller as Company)?.representative?.photoDni?.[0],
              icon: <IdentificationIcon className="w-5 h-5" />,
              owner: (seller as Company)?.representative,
              fileType: FILE_TYPE.DOI_BACK,
            },
            {
              label: 'Mandato - Representante Vendedor',
              docURI: mandate
                ? `${process.env.REACT_APP_SUZUKI_URL}/documents/download/${
                    mandate?.serviceId
                  }?original=${
                    mandate?.status === 'PENDING' ? 'true' : 'false'
                  }`
                : (seller as Company)?.representative?.mandate,
              icon: <IdentificationIcon className="w-5 h-5" />,
              owner: (seller as Company)?.representative,
              fileType: FILE_TYPE.MANDATE,
              signatureStatus: mandate?.status,
              signers: mandate?.signers,
              suzukiDocumentId: mandate?.id,
              canUseDigitalSignature: !(
                mandate || (seller as Company)?.representative?.mandate
              ),
            },
            {
              label: 'Permiso de circulación',
              docURI: car.circulationPermit,
              icon: <AiOutlineCar className="w-5 h-5" />,
              owner: car,
              fileType: FILE_TYPE.CIRCULATION_PERMIT,
            },
            {
              label: 'Ficha técnica',
              docURI: car.technicalSheet,
              icon: <AiOutlineCar className="w-5 h-5" />,
              owner: car,
              fileType: FILE_TYPE.TECHNICAL_SHEET,
            },
            {
              label: 'Factura',
              docURI: (tramit as BateTramit).bate?.proInvoice,
              icon: <DocumentTextIcon className="w-5 h-5" />,
              owner: (tramit as BateTramit).bate,
              fileType: FILE_TYPE.PRO_INVOICE,
            },
            {
              label: 'Contrato de compraventa',
              docURI: saleContract
                ? `${process.env.REACT_APP_SUZUKI_URL}/documents/download/${
                    saleContract?.serviceId
                  }?original=${
                    saleContract.status === 'PENDING' ? 'true' : 'false'
                  }`
                : (bate as Bate)?.saleContract,
              icon: <DocumentTextIcon className="w-5 h-5" />,
              owner: bate,
              fileType: FILE_TYPE.SALE_CONTRACT,
              signatureStatus: saleContract?.status,
              signers: saleContract?.signers,
              suzukiDocumentId: saleContract?.id,
              canUseDigitalSignature: !(
                saleContract || (bate as Bate)?.saleContract
              ),
            },
          ].filter(Boolean)
        : [
            {
              label: 'DNI Frontal - Vendedor',
              docURI: (seller as User)?.photoDni?.[0],
              icon: <IdentificationIcon className="w-5 h-5" />,
              fileType: FILE_TYPE.DOI_FRONT,
              owner: seller,
            },
            {
              label: 'DNI Reverso - Vendedor',
              docURI: (seller as User)?.photoDni?.[1],
              icon: <IdentificationIcon className="w-5 h-5" />,
              fileType: FILE_TYPE.DOI_BACK,
              owner: seller,
            },
            {
              label: 'Mandato - Vendedor',
              docURI: mandate
                ? `${process.env.REACT_APP_SUZUKI_URL}/documents/download/${
                    mandate?.serviceId
                  }?original=${
                    mandate?.status === 'PENDING' ? 'true' : 'false'
                  }`
                : (seller as User)?.mandate,
              icon: <IdentificationIcon className="w-5 h-5" />,
              owner: seller,
              fileType: FILE_TYPE.MANDATE,
              signatureStatus: mandate?.status,
              signers: mandate?.signers,
              suzukiDocumentId: mandate?.id,
              canUseDigitalSignature: !(mandate || (seller as User)?.mandate),
            },
            {
              label: 'Permiso de circulación',
              docURI: car.circulationPermit,
              icon: <AiOutlineCar className="w-5 h-5" />,
              fileType: FILE_TYPE.CIRCULATION_PERMIT,
              owner: car,
            },
            {
              label: 'Ficha técnica',
              docURI: car.technicalSheet,
              icon: <AiOutlineCar className="w-5 h-5" />,
              fileType: FILE_TYPE.TECHNICAL_SHEET,
              owner: car,
            },
            {
              label: 'Contrato de compraventa',
              docURI: saleContract
                ? `${process.env.REACT_APP_SUZUKI_URL}/documents/download/${
                    saleContract?.serviceId
                  }?original=${
                    saleContract.status === 'PENDING' ? 'true' : 'false'
                  }`
                : (bate as Bate)?.saleContract,
              icon: <DocumentTextIcon className="w-5 h-5" />,
              owner: bate,
              fileType: FILE_TYPE.SALE_CONTRACT,
              signatureStatus: saleContract?.status,
              signers: saleContract?.signers,
              suzukiDocumentId: saleContract?.id,
              canUseDigitalSignature: !(
                saleContract || (bate as Bate)?.saleContract
              ),
            },
          ].filter(Boolean);
    }
    if (entityType === 'transaction') {
      const { operationType, buyer, seller, car, transaction } =
        tramit as Transfer;
      const sellerIsAccount = (seller as Company)?.nif === companyInfo.nif;
      const buyerIsAccount = (buyer as Company)?.nif === companyInfo.nif;
      switch (operationType) {
        case 'B2P': {
          const [mandate] = mandates;
          return [
            !buyerIsAccount && {
              label: 'Tarjeta Identificación Fiscal - Comprador',
              docURI: (buyer as Company)?.nifFile,
              icon: <OfficeBuildingIcon className="w-5 h-5" />,
              fileType: FILE_TYPE.TIF,
              owner: buyer,
            },
            !buyerIsAccount && {
              label: 'DNI Frontal - Representante Comprador',
              docURI: (buyer as Company)?.representative?.photoDni?.[0],
              icon: <IdentificationIcon className="w-5 h-5" />,
              fileType: FILE_TYPE.DOI_FRONT,
              owner: (buyer as Company)?.representative,
            },
            !buyerIsAccount && {
              label: 'DNI Reverso - Representante Comprador',
              docURI: (buyer as Company)?.representative?.photoDni?.[1],
              icon: <IdentificationIcon className="w-5 h-5" />,
              fileType: FILE_TYPE.DOI_BACK,
              owner: (buyer as Company)?.representative,
            },
            !buyerIsAccount && {
              label: 'Mandato - Representante Comprador',
              docURI: mandate
                ? `${process.env.REACT_APP_SUZUKI_URL}/documents/download/${
                    mandate?.serviceId
                  }?original=${
                    mandate?.status === 'PENDING' ? 'true' : 'false'
                  }`
                : (buyer as Company)?.representative?.mandate,
              icon: <IdentificationIcon className="w-5 h-5" />,
              owner: (buyer as Company)?.representative,
              fileType: FILE_TYPE.MANDATE,
              signatureStatus: mandate?.status,
              signers: mandate?.signers,
              suzukiDocumentId: mandate?.id,
              canUseDigitalSignature: !(
                mandate || (buyer as Company)?.representative?.mandate
              ),
            },
            {
              label: 'DNI Frontal - Vendedor',
              docURI: (seller as User)?.photoDni?.[0],
              icon: <IdentificationIcon className="w-5 h-5" />,
              fileType: FILE_TYPE.DOI_FRONT,
              owner: seller,
            },
            {
              label: 'DNI Reverso - Vendedor',
              docURI: (seller as User)?.photoDni?.[1],
              icon: <IdentificationIcon className="w-5 h-5" />,
              fileType: FILE_TYPE.DOI_BACK,
              owner: seller,
            },
            {
              label: 'Mandato - Vendedor',
              docURI: mandate
                ? `${process.env.REACT_APP_SUZUKI_URL}/documents/download/${
                    mandate?.serviceId
                  }?original=${
                    mandate?.status === 'PENDING' ? 'true' : 'false'
                  }`
                : (seller as User)?.mandate,
              icon: <IdentificationIcon className="w-5 h-5" />,
              owner: seller,
              fileType: FILE_TYPE.MANDATE,
              signatureStatus: mandate?.status,
              signers: mandate?.signers,
              suzukiDocumentId: mandate?.id,
              canUseDigitalSignature: !(mandate || (seller as User)?.mandate),
            },
            {
              label: 'Permiso de circulación',
              docURI: car.circulationPermit,
              icon: <AiOutlineCar className="w-5 h-5" />,
              fileType: FILE_TYPE.CIRCULATION_PERMIT,
              owner: car,
            },
            {
              label: 'Ficha técnica',
              docURI: car.technicalSheet,
              icon: <AiOutlineCar className="w-5 h-5" />,
              fileType: FILE_TYPE.TECHNICAL_SHEET,
              owner: car,
            },
            {
              label: 'Contrato de compraventa',
              docURI: saleContract
                ? `${process.env.REACT_APP_SUZUKI_URL}/documents/download/${
                    saleContract?.serviceId
                  }?original=${
                    saleContract.status === 'PENDING' ? 'true' : 'false'
                  }`
                : transaction?.saleContract,
              icon: <DocumentTextIcon className="w-5 h-5" />,
              owner: transaction,
              fileType: FILE_TYPE.SALE_CONTRACT,
              signatureStatus: saleContract?.status,
              signers: saleContract?.signers,
              suzukiDocumentId: saleContract?.id,
              canUseDigitalSignature: !(
                saleContract || transaction?.saleContract
              ),
            },
          ].filter(Boolean);
        }
        case 'B2B': {
          const [mandate] = mandates;
          return [
            !buyerIsAccount && {
              label: 'Tarjeta Identificación Fiscal - Comprador',
              docURI: (buyer as Company)?.nifFile,
              icon: <OfficeBuildingIcon className="w-5 h-5" />,
              owner: buyer,
              fileType: FILE_TYPE.TIF,
            },
            !buyerIsAccount && {
              label: 'DNI Frontal - Representante Comprador',
              docURI: (buyer as Company)?.representative?.photoDni?.[0],
              icon: <IdentificationIcon className="w-5 h-5" />,
              owner: (buyer as Company)?.representative,
              fileType: FILE_TYPE.DOI_FRONT,
            },
            !buyerIsAccount && {
              label: 'DNI Reverso - Representante Comprador',
              docURI: (buyer as Company)?.representative?.photoDni?.[1],
              icon: <IdentificationIcon className="w-5 h-5" />,
              owner: (buyer as Company)?.representative,
              fileType: FILE_TYPE.DOI_BACK,
            },
            !buyerIsAccount && {
              label: 'Mandato - Representante Comprador',
              docURI: mandate
                ? `${process.env.REACT_APP_SUZUKI_URL}/documents/download/${
                    mandate?.serviceId
                  }?original=${
                    mandate?.status === 'PENDING' ? 'true' : 'false'
                  }`
                : (buyer as Company)?.representative?.mandate,
              icon: <IdentificationIcon className="w-5 h-5" />,
              owner: (buyer as Company)?.representative,
              fileType: FILE_TYPE.MANDATE,
              signatureStatus: mandate?.status,
              signers: mandate?.signers,
              suzukiDocumentId: mandate?.id,
              canUseDigitalSignature: !(
                mandate || (buyer as Company)?.representative?.mandate
              ),
            },
            !sellerIsAccount && {
              label: 'Tarjeta Identificación Fiscal - Vendedor',
              docURI: (seller as Company)?.nifFile,
              icon: <OfficeBuildingIcon className="w-5 h-5" />,
              owner: seller,
              fileType: FILE_TYPE.TIF,
            },
            !sellerIsAccount && {
              label: 'DNI Frontal - Representante Vendedor',
              docURI: (seller as Company)?.representative?.photoDni?.[1],
              icon: <IdentificationIcon className="w-5 h-5" />,
              owner: (seller as Company)?.representative,
              fileType: FILE_TYPE.DOI_FRONT,
            },
            !sellerIsAccount && {
              label: 'DNI Reverso - Representante Vendedor',
              docURI: (seller as Company)?.representative?.photoDni?.[0],
              icon: <IdentificationIcon className="w-5 h-5" />,
              owner: (seller as Company)?.representative,
              fileType: FILE_TYPE.DOI_BACK,
            },
            !sellerIsAccount && {
              label: 'Mandato - Representante Vendedor',
              docURI: mandate
                ? `${process.env.REACT_APP_SUZUKI_URL}/documents/download/${
                    mandate?.serviceId
                  }?original=${
                    mandate?.status === 'PENDING' ? 'true' : 'false'
                  }`
                : (seller as Company)?.representative?.mandate,
              icon: <IdentificationIcon className="w-5 h-5" />,
              owner: (seller as Company)?.representative,
              fileType: FILE_TYPE.MANDATE,
              signatureStatus: mandate?.status,
              signers: mandate?.signers,
              suzukiDocumentId: mandate?.id,
              canUseDigitalSignature: !(
                mandate || (seller as Company)?.representative?.mandate
              ),
            },
            {
              label: 'Permiso de circulación',
              docURI: car.circulationPermit,
              icon: <AiOutlineCar className="w-5 h-5" />,
              owner: car,
              fileType: FILE_TYPE.CIRCULATION_PERMIT,
            },
            {
              label: 'Ficha técnica',
              docURI: car.technicalSheet,
              icon: <AiOutlineCar className="w-5 h-5" />,
              owner: car,
              fileType: FILE_TYPE.TECHNICAL_SHEET,
            },
            {
              label: 'Factura',
              docURI: transaction.proInvoice,
              icon: <DocumentTextIcon className="w-5 h-5" />,
              owner: transaction,
              fileType: FILE_TYPE.PRO_INVOICE,
            },
            {
              label: 'Contrato de compraventa',
              docURI: saleContract
                ? `${process.env.REACT_APP_SUZUKI_URL}/documents/download/${
                    saleContract?.serviceId
                  }?original=${
                    saleContract.status === 'PENDING' ? 'true' : 'false'
                  }`
                : transaction?.saleContract,
              icon: <DocumentTextIcon className="w-5 h-5" />,
              owner: transaction,
              fileType: FILE_TYPE.SALE_CONTRACT,
              signatureStatus: saleContract?.status,
              signers: saleContract?.signers,
              suzukiDocumentId: saleContract?.id,
              canUseDigitalSignature: !(
                saleContract || transaction?.saleContract
              ),
            },
          ].filter(Boolean);
        }
        case 'P2B': {
          const [mandate] = mandates;
          return [
            {
              label: 'DNI Frontal - Comprador',
              docURI: (buyer as User)?.photoDni?.[0],
              icon: <IdentificationIcon className="w-5 h-5" />,
              owner: buyer,
              fileType: FILE_TYPE.DOI_FRONT,
            },
            {
              label: 'DNI Reverso - Comprador',
              docURI: (buyer as User)?.photoDni?.[1],
              icon: <IdentificationIcon className="w-5 h-5" />,
              owner: buyer,
              fileType: FILE_TYPE.DOI_BACK,
            },
            {
              label: 'Mandato - Comprador',
              docURI: mandate
                ? `${process.env.REACT_APP_SUZUKI_URL}/documents/download/${
                    mandate?.serviceId
                  }?original=${
                    mandate?.status === 'PENDING' ? 'true' : 'false'
                  }`
                : (buyer as User)?.mandate,
              icon: <IdentificationIcon className="w-5 h-5" />,
              owner: buyer,
              fileType: FILE_TYPE.MANDATE,
              signatureStatus: mandate?.status,
              signers: mandate?.signers,
              suzukiDocumentId: mandate?.id,
              canUseDigitalSignature: !(mandate || (buyer as User)?.mandate),
            },
            !sellerIsAccount && {
              label: 'Tarjeta Identificación Fiscal - Vendedor',
              docURI: (seller as Company)?.nifFile,
              icon: <OfficeBuildingIcon className="w-5 h-5" />,
              owner: seller,
              fileType: FILE_TYPE.TIF,
            },
            !sellerIsAccount && {
              label: 'DNI Frontal - Representante Vendedor',
              docURI: (seller as Company)?.representative?.photoDni?.[0],
              icon: <IdentificationIcon className="w-5 h-5" />,
              owner: (seller as Company)?.representative,
              fileType: FILE_TYPE.DOI_FRONT,
            },
            !sellerIsAccount && {
              label: 'DNI Reverso - Representante Vendedor',
              docURI: (seller as Company)?.representative?.photoDni?.[1],
              icon: <IdentificationIcon className="w-5 h-5" />,
              owner: (seller as Company)?.representative,
              fileType: FILE_TYPE.DOI_BACK,
            },
            !sellerIsAccount && {
              label: 'Mandato - Representante Vendedor',
              docURI: mandate
                ? `${process.env.REACT_APP_SUZUKI_URL}/documents/download/${
                    mandate?.serviceId
                  }?original=${
                    mandate?.status === 'PENDING' ? 'true' : 'false'
                  }`
                : (seller as Company)?.representative?.mandate,
              icon: <IdentificationIcon className="w-5 h-5" />,
              owner: (seller as Company)?.representative,
              fileType: FILE_TYPE.MANDATE,
              signatureStatus: mandate?.status,
              signers: mandate?.signers,
              suzukiDocumentId: mandate?.id,
              canUseDigitalSignature: !(
                mandate || (seller as Company)?.representative?.mandate
              ),
            },
            {
              label: 'Permiso de circulación',
              docURI: car.circulationPermit,
              icon: <AiOutlineCar className="w-5 h-5" />,
              owner: car,
              fileType: FILE_TYPE.CIRCULATION_PERMIT,
            },
            {
              label: 'Ficha técnica',
              docURI: car.technicalSheet,
              icon: <AiOutlineCar className="w-5 h-5" />,
              owner: car,
              fileType: FILE_TYPE.TECHNICAL_SHEET,
            },
            {
              label: 'Factura',
              docURI: transaction.proInvoice,
              icon: <DocumentTextIcon className="w-5 h-5" />,
              fileType: FILE_TYPE.PRO_INVOICE,
              owner: transaction,
            },
            {
              label: 'Contrato de compraventa',
              docURI: saleContract
                ? `${process.env.REACT_APP_SUZUKI_URL}/documents/download/${
                    saleContract?.serviceId
                  }?original=${
                    saleContract.status === 'PENDING' ? 'true' : 'false'
                  }`
                : transaction?.saleContract,
              icon: <DocumentTextIcon className="w-5 h-5" />,
              owner: transaction,
              fileType: FILE_TYPE.SALE_CONTRACT,
              signatureStatus: saleContract?.status,
              signers: saleContract?.signers,
              suzukiDocumentId: saleContract?.id,
              canUseDigitalSignature: !(
                saleContract || transaction?.saleContract
              ),
            },
          ].filter(Boolean);
        }
        case 'P2P': {
          // change logic after SWIP-738
          const [sellerMandate, buyerMandate] = mandates;
          return [
            {
              label: 'DNI Frontal - Comprador',
              docURI: (buyer as User)?.photoDni?.[0],
              icon: <IdentificationIcon className="w-5 h-5" />,
              owner: buyer,
              fileType: FILE_TYPE.DOI_FRONT,
            },
            {
              label: 'DNI Reverso - Comprador',
              docURI: (buyer as User)?.photoDni?.[1],
              icon: <IdentificationIcon className="w-5 h-5" />,
              owner: buyer,
              fileType: FILE_TYPE.DOI_BACK,
            },
            {
              label: 'Mandato - Comprador',
              docURI: buyerMandate
                ? `${process.env.REACT_APP_SUZUKI_URL}/documents/download/${
                    buyerMandate?.serviceId
                  }?original=${
                    buyerMandate?.status === 'PENDING' ? 'true' : 'false'
                  }`
                : (buyer as User)?.mandate,
              icon: <IdentificationIcon className="w-5 h-5" />,
              owner: buyer,
              fileType: FILE_TYPE.MANDATE,
              signatureStatus: buyerMandate?.status,
              signers: buyerMandate?.signers,
              suzukiDocumentId: buyerMandate?.id,
              canUseDigitalSignature: !(
                buyerMandate || (buyer as User)?.mandate
              ),
            },
            {
              label: 'DNI Frontal - Vendedor',
              docURI: (seller as User)?.photoDni?.[0],
              icon: <IdentificationIcon className="w-5 h-5" />,
              owner: seller,
              fileType: FILE_TYPE.DOI_FRONT,
            },
            {
              label: 'DNI Reverso - Vendedor',
              docURI: (seller as User)?.photoDni?.[1],
              icon: <IdentificationIcon className="w-5 h-5" />,
              owner: seller,
              fileType: FILE_TYPE.DOI_BACK,
            },
            {
              label: 'Mandato - Vendedor',
              docURI: sellerMandate
                ? `${process.env.REACT_APP_SUZUKI_URL}/documents/download/${
                    sellerMandate?.serviceId
                  }?original=${
                    sellerMandate?.status === 'PENDING' ? 'true' : 'false'
                  }`
                : (seller as User)?.mandate,
              icon: <IdentificationIcon className="w-5 h-5" />,
              owner: seller,
              fileType: FILE_TYPE.MANDATE,
              signatureStatus: sellerMandate?.status,
              signers: sellerMandate?.signers,
              suzukiDocumentId: sellerMandate?.id,
              canUseDigitalSignature: !(
                sellerMandate || (seller as User)?.mandate
              ),
            },
            {
              label: 'Permiso de circulación',
              docURI: car.circulationPermit,
              icon: <AiOutlineCar className="w-5 h-5" />,
              owner: car,
              fileType: FILE_TYPE.CIRCULATION_PERMIT,
            },
            {
              label: 'Ficha técnica',
              docURI: car.technicalSheet,
              icon: <AiOutlineCar className="w-5 h-5" />,
              owner: car,
              fileType: FILE_TYPE.TECHNICAL_SHEET,
            },
            {
              label: 'Contrato de compraventa',
              docURI: saleContract
                ? `${process.env.REACT_APP_SUZUKI_URL}/documents/download/${
                    saleContract?.serviceId
                  }?original=${
                    saleContract.status === 'PENDING' ? 'true' : 'false'
                  }`
                : transaction?.saleContract,
              icon: <DocumentTextIcon className="w-5 h-5" />,
              owner: transaction,
              fileType: FILE_TYPE.SALE_CONTRACT,
              signatureStatus: saleContract?.status,
              signers: saleContract?.signers,
              suzukiDocumentId: saleContract?.id,
              canUseDigitalSignature: !(
                saleContract || transaction?.saleContract
              ),
            },
          ].filter(Boolean);
        }
        default:
          return [];
      }
    }
    if (entityType === 'registration') {
      const { car, buyer } = tramit as RegistrationTramit;
      const [mandate] = mandates;
      return (buyer as Company)?.representative
        ? [
            {
              label: 'Tarjeta Identificación Fiscal - Comprador',
              docURI: (buyer as Company)?.nifFile,
              icon: <OfficeBuildingIcon className="w-5 h-5" />,
              owner: buyer,
              fileType: FILE_TYPE.TIF,
            },
            {
              label: 'DNI Frontal - Representante Comprador',
              docURI: (buyer as Company)?.representative?.photoDni?.[0],
              icon: <IdentificationIcon className="w-5 h-5" />,
              owner: (buyer as Company)?.representative,
              fileType: FILE_TYPE.DOI_FRONT,
            },
            {
              label: 'DNI Reverso - Representante Comprador',
              docURI: (buyer as Company)?.representative?.photoDni?.[1],
              icon: <IdentificationIcon className="w-5 h-5" />,
              owner: (buyer as Company)?.representative,
              fileType: FILE_TYPE.DOI_BACK,
            },
            {
              label: 'Mandato - Representante Comprador',
              docURI: mandate
                ? `${process.env.REACT_APP_SUZUKI_URL}/documents/download/${
                    mandate?.serviceId
                  }?original=${
                    mandate?.status === 'PENDING' ? 'true' : 'false'
                  }`
                : (buyer as Company)?.representative?.mandate,
              icon: <IdentificationIcon className="w-5 h-5" />,
              owner: (buyer as Company)?.representative,
              fileType: FILE_TYPE.MANDATE,
              signatureStatus: mandate?.status,
              signers: mandate?.signers,
              suzukiDocumentId: mandate?.id,
              canUseDigitalSignature: !(
                mandate || (buyer as Company)?.representative?.mandate
              ),
            },
            {
              label: 'Permiso de circulación',
              docURI: car.circulationPermit,
              icon: <AiOutlineCar className="w-5 h-5" />,
              owner: car,
              fileType: FILE_TYPE.CIRCULATION_PERMIT,
            },
            {
              label: 'Ficha técnica',
              docURI: car.technicalSheet,
              icon: <AiOutlineCar className="w-5 h-5" />,
              owner: car,
              fileType: FILE_TYPE.TECHNICAL_SHEET,
            },
          ].filter(Boolean)
        : [
            {
              label: 'DNI Frontal - Comprador',
              docURI: (buyer as User)?.photoDni?.[0],
              icon: <IdentificationIcon className="w-5 h-5" />,
              fileType: FILE_TYPE.DOI_FRONT,
              owner: buyer,
            },
            {
              label: 'DNI Reverso - Comprador',
              docURI: (buyer as User)?.photoDni?.[1],
              icon: <IdentificationIcon className="w-5 h-5" />,
              fileType: FILE_TYPE.DOI_BACK,
              owner: buyer,
            },
            {
              label: 'Mandato - Comprador',
              docURI: mandate
                ? `${process.env.REACT_APP_SUZUKI_URL}/documents/download/${
                    mandate?.serviceId
                  }?original=${
                    mandate?.status === 'PENDING' ? 'true' : 'false'
                  }`
                : (buyer as User)?.mandate,
              icon: <IdentificationIcon className="w-5 h-5" />,
              fileType: FILE_TYPE.MANDATE,
              owner: buyer,
              signatureStatus: mandate?.status,
              signers: mandate?.signers,
              suzukiDocumentId: mandate?.id,
              canUseDigitalSignature: !(mandate || (buyer as User)?.mandate),
            },
            {
              label: 'Permiso de circulación',
              docURI: car.circulationPermit,
              icon: <AiOutlineCar className="w-5 h-5" />,
              fileType: FILE_TYPE.CIRCULATION_PERMIT,
              owner: car,
            },
            {
              label: 'Ficha técnica',
              docURI: car.technicalSheet,
              icon: <AiOutlineCar className="w-5 h-5" />,
              fileType: FILE_TYPE.TECHNICAL_SHEET,
              owner: car,
            },
          ].filter(Boolean);
    }
    return [];
  };

  const withMissplacement = useCallback(
    (rows: RowData[]): RowData[] => [
      ...rows,
      {
        label: 'Declaración de extravío',
        docURI:
          missplacement &&
          `${process.env.REACT_APP_SUZUKI_URL}/documents/download/${
            missplacement?.serviceId
          }?original=${missplacement?.status === 'PENDING' ? 'true' : 'false'}`,
        icon: <DocumentTextIcon className="w-5 h-5" />,
        fileType: FILE_TYPE.MISSPLACEMENT,
        owner: null,
        signatureStatus: missplacement?.status,
        signers: missplacement?.signers,
        suzukiDocumentId: missplacement?.id,
        canUseDigitalSignature: true,
        hideAttachementButton: true,
      },
    ],
    [missplacement],
  );

  const withOtherDocuments = useCallback(
    (rows: RowData[]): RowData[] => [
      ...rows,
      ...otherDocs.map((doc) => ({
        label: 'Otros documentos',
        docURI:
          doc &&
          `${process.env.REACT_APP_SUZUKI_URL}/documents/download/${
            doc?.serviceId
          }?original=${doc?.status === 'PENDING' ? 'true' : 'false'}`,
        icon: <DocumentTextIcon className="w-5 h-5" />,
        fileType: null as null,
        owner: null as null,
        signatureStatus: doc?.status,
        signers: doc?.signers,
        suzukiDocumentId: doc?.id,
        canUseDigitalSignature: true,
        hideAttachementButton: true,
      })),
      {
        label: 'Otros documentos',
        docURI: '',
        icon: <DocumentTextIcon className="w-5 h-5" />,
        fileType: null as null,
        owner: null as null,
        canUseDigitalSignature: true,
        hideAttachementButton: true,
      },
    ],
    [otherDocs],
  );

  return (
    <div className="mt-6 flex flex-col w-full">
      <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
          {Boolean(agencyDocumentsRows.length) && (
            <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg my-4">
              <AgencyDocumentsTable agencyDocumentsRows={agencyDocumentsRows} />
            </div>
          )}
          <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg my-4">
            <table className="min-w-full divide-y divide-gray-300">
              <thead className="bg-gray-50">
                <tr>
                  <th
                    scope="col"
                    className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                  >
                    Documentos para el trámite
                  </th>
                  <th
                    scope="col"
                    className="px-8 py-3.5 text-right text-sm font-semibold text-gray-900"
                  >
                    Acciones
                  </th>
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-200 bg-white">
                <TableBody
                  tramit={tramit}
                  tramitType={entityType}
                  forceUpdate={handleUpdateData}
                  templateRows={withOtherDocuments(
                    withMissplacement(generateTemplateRows()),
                  )}
                />
              </tbody>
            </table>
          </div>
          <DragAndDropFile
            id={getTramitId()}
            entityType={entityType}
            forceUpdate={forceUpdate}
          />
        </div>
      </div>
    </div>
  );
}

export default Documents;
