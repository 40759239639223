import React from 'react';

interface DocumentSourceOptionProps {
  icon: React.ReactNode;
  title: string;
  description: string;
  isChecked: boolean;
  onClick: () => void;
}

export default function DocumentSourceOption({
  icon,
  title,
  description,
  isChecked,
  onClick,
}: Readonly<DocumentSourceOptionProps>) {
  if (isChecked) {
    return (
      <button
        type="button"
        role="radio"
        aria-checked="true"
        className="flex flex-col items-start gap-6 rounded-2xl p-8 border-2 bg-blue-50 border-blue-800 text-blue-800 focus:outline-none focus:ring-2 focus:ring-blue-500"
        onClick={onClick}
      >
        {icon}
        <section className="flex flex-col items-start gap-2">
          <h2 className="text-xl font-semibold">{title}</h2>
          <span className="text-left w-80">{description}</span>
        </section>
      </button>
    );
  }

  return (
    <button
      type="button"
      role="radio"
      aria-checked="false"
      className="flex flex-col items-start gap-6 rounded-2xl p-8 border-2 border-zinc-200 hover:bg-zinc-50 text-zinc-700"
      onClick={onClick}
    >
      {icon}
      <section className="flex flex-col items-start gap-2">
        <h2 className="text-xl font-semibold">{title}</h2>
        <span className="text-left w-80 text-zinc-600">{description}</span>
      </section>
    </button>
  );
}
