import React, { Dispatch, SetStateAction, useRef, useState } from 'react';
import {
  MdCheck,
  MdCheckCircle,
  MdEdit,
  MdMail,
  MdOutlineClose,
  MdPhone,
} from 'react-icons/md';
import axios from 'axios';
import { useAuth0 } from '@auth0/auth0-react';
import { parsePhoneNumber } from 'libphonenumber-js';

import { User } from '../../../../../types/types';
import RecipientSendingMethod, {
  SendingMethod,
} from './RecipientSendingMethod';
import RecipientItemIcon from './RecipientItemIcon';

export enum AvailableRecipientRoles {
  BUYER = 'Comprador',
  SELLER = 'Vendedor',
}

interface RecipientItemProps {
  recipientRole: AvailableRecipientRoles;
  recipient: User;
  updateRecipient: Dispatch<SetStateAction<User>>;
  isMotoOcasion?: boolean;
  recipientSendingMethod: SendingMethod | '';
  setRecipientSendingMethod: Dispatch<SetStateAction<SendingMethod>>;
  hasSigned?: boolean;
}

export default function RecipientItem({
  recipientRole,
  recipient,
  updateRecipient,
  isMotoOcasion = false,
  recipientSendingMethod,
  setRecipientSendingMethod,
  hasSigned = false,
}: Readonly<RecipientItemProps>) {
  const [isEditingRecipient, setIsEditingRecipient] = useState<boolean>(false);
  const [isRecipientEmailValid, setIsRecipientEmailValid] =
    useState<boolean>(false);
  const [isRecipientPhoneValid, setIsRecipientPhoneValid] =
    useState<boolean>(false);
  const recipientEmailInputRef = useRef<HTMLInputElement | null>(null);
  const recipientPhoneInputRef = useRef<HTMLInputElement | null>(null);

  const { getAccessTokenSilently } = useAuth0();

  const handleConfirmEdit = async () => {
    const token = await getAccessTokenSilently();
    const phone = parsePhoneNumber(
      recipientPhoneInputRef.current.value,
      'ES',
    ).number;
    const email = recipientEmailInputRef.current.value;
    await axios.put(
      `${process.env.REACT_APP_BASE_API_URL}/edit-user/${recipient.id}`,
      {
        email,
        phone,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    );

    setIsEditingRecipient(false);
    updateRecipient({
      ...recipient,
      email,
      phone,
    });
  };

  const handleEmailChange = () => {
    if (!recipientEmailInputRef.current) {
      return;
    }

    if (recipientEmailInputRef.current.value.length > 0) {
      setIsRecipientEmailValid(true);
    }
  };

  const handlePhoneChange = () => {
    if (!recipientPhoneInputRef.current) {
      return;
    }

    if (recipientPhoneInputRef.current.value.length > 0) {
      setIsRecipientPhoneValid(true);
    }
  };

  if (!recipient) {
    return (
      <section className="flex gap-6 items-center text-zinc-600">
        <RecipientItemIcon role={recipientRole} isDisabled />
        <article className="flex flex-col gap-1.5">
          <span className="font-semibold text-lg">
            No hay datos disponibles del {recipientRole.toLowerCase()}
          </span>
          <span className="text-sm w-80">
            Es necesario completar esta información para enviar la solicitud de
            firma digital al {recipientRole.toLowerCase()}.
          </span>
        </article>
      </section>
    );
  }

  if (isEditingRecipient) {
    return (
      <article className="flex justify-between items-center">
        <section className="flex gap-6 items-center">
          <RecipientItemIcon role={recipientRole} />
          <article className="flex flex-col gap-4">
            <span className="font-bold">{`${recipient.name} ${recipient.surname}`}</span>
            <section className="grid grid-cols-3 gap-2">
              <article className="flex flex-col gap-1">
                <label
                  htmlFor="recipient-email"
                  className="text-xs text-zinc-600 font-semibold"
                >
                  Correo electrónico
                </label>
                <input
                  ref={recipientEmailInputRef}
                  id="recipient-email"
                  type="email"
                  className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                  defaultValue={recipient.email}
                  onChange={handleEmailChange}
                />
              </article>
              <article className="flex flex-col gap-1">
                <label
                  htmlFor="recipient-phone"
                  className="text-xs text-zinc-600 font-semibold"
                >
                  Teléfono
                </label>
                <input
                  ref={recipientPhoneInputRef}
                  id="recipient-phone"
                  type="tel"
                  className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                  defaultValue={recipient.phone}
                  onChange={handlePhoneChange}
                />
              </article>
              <article className="flex justify-end items-end gap-2">
                <button
                  type="button"
                  className="flex items-center justify-center p-2 rounded-lg text-zinc-800 hover:bg-zinc-100 active:bg-zinc-200"
                  aria-label="Cancelar edición"
                  title="Cancelar edición"
                  onClick={() => setIsEditingRecipient(false)}
                >
                  <MdOutlineClose className="size-5" />
                </button>
                <button
                  type="button"
                  className="flex items-center justify-center p-2 rounded-lg text-blue-800 bg-blue-50 hover:bg-blue-100 active:bg-blue-200 disabled:opacity-50 disabled:cursor-not-allowed"
                  aria-label="Confirmar edición"
                  title="Confirmar edición"
                  disabled={!isRecipientEmailValid && !isRecipientPhoneValid}
                  onClick={handleConfirmEdit}
                >
                  <MdCheck className="size-5" />
                </button>
              </article>
            </section>
          </article>
        </section>
      </article>
    );
  }

  return (
    <article className="flex justify-between items-center gap-10">
      <section className="flex gap-6 items-center">
        <RecipientItemIcon role={recipientRole} />
        <article className="flex flex-col gap-1.5">
          <section className="flex items-center justify-center gap-2">
            <span className="font-bold">{`${recipient.name} ${recipient.surname}`}</span>
            {!hasSigned && (
              <button
                type="button"
                className="flex items-center justify-center text-zinc-400 p-2 rounded-lg hover:text-zinc-800 hover:bg-zinc-100 active:bg-zinc-200"
                aria-label="Editar datos del destinatario"
                title="Editar datos del destinatario"
                onClick={() => setIsEditingRecipient(true)}
              >
                <MdEdit className="size-5" />
              </button>
            )}
          </section>
          <article className="text-sm flex flex-col gap-0.5">
            <span className="flex items-center gap-2 text-zinc-600">
              <MdMail className="size-4" /> {recipient.email || 'No disponible'}
            </span>
            <span className="flex items-center gap-2 text-zinc-600">
              <MdPhone className="size-4" />{' '}
              {recipient.phone || 'No disponible'}
            </span>
          </article>
        </article>
      </section>
      {hasSigned ? (
        <article className="flex justify-center items-center gap-3 px-3 py-2.5 text-green-800 font-semibold">
          <MdCheckCircle className="size-6" />
          Firmado
        </article>
      ) : (
        <RecipientSendingMethod
          recipient={recipient}
          sendingMethod={recipientSendingMethod}
          setSendingMethod={setRecipientSendingMethod}
          isMotoOcasion={isMotoOcasion}
        />
      )}
    </article>
  );
}
