/* eslint-disable react/jsx-props-no-spreading */
import ItpDetails from './itpDetails';
import ItpForm from './itpForm';
import useItp from '../../../../hooks/useItp';

export default function ItpCalculator() {
  const {
    itpFormValue,
    handleChange,
    setCc,
    setCcaa,
    itpResetValues,
    isModelLoading,
    modelError,
    modelData,
    isFormComplete,
    selectedModel,
    handleModelSelection,
    ccaa,
    itpData,
    isItpLoading,
    itpError,
    cc,
  } = useItp();

  return (
    <form className="space-y-8 divide-y divide-gray-200 border-2 px-8 pb-4 rounded-lg w-full h-[fit-content] min-h-[230px]">
      <div className="space-y-8 divide-y divide-gray-200 sm:space-y-5">
        <div className="space-y-6 sm:pt-4 sm:space-y-5">
          <div>
            <h3 className="text-lg leading-6 font-medium text-gray-900">
              ITP de vehículos
            </h3>
            <p className="mt-1 max-w-2xl text-sm text-gray-500">
              Calcula y consulta el ITP y el valor de hacienda de un modelo.
            </p>
          </div>
          <ItpForm
            itpFormValue={itpFormValue}
            handleChange={handleChange}
            setCc={setCc}
            setCcaa={setCcaa}
            itpResetValues={itpResetValues}
            isModelLoading={isModelLoading}
            modelError={modelError}
            modelData={modelData}
            isFormComplete={isFormComplete}
            selectedModel={selectedModel}
            handleModelSelection={handleModelSelection}
            ccaa={ccaa}
            isItpLoading={isItpLoading}
            itpError={itpError}
            cc={cc}
          />
          {itpData && <ItpDetails itpData={itpData} />}
        </div>
      </div>
    </form>
  );
}
