import React, {
  ChangeEvent,
  Dispatch,
  SetStateAction,
  useContext,
} from 'react';
import { User } from '../../../../../types/types';
import { userInfoContext } from '../../../../../context/UserInfoContext/UserInfoContext';

export enum SendingMethod {
  EMAIL = 'EMAIL',
  WHATSAPP = 'WHATSAPP',
  CERTIFICATE = 'CERTIFICATE',
}

interface RecipientSendingMethodProps {
  recipient: User;
  sendingMethod: SendingMethod | '';
  setSendingMethod: Dispatch<SetStateAction<SendingMethod>>;
  isMotoOcasion?: boolean;
}

export default function RecipientSendingMethod({
  recipient,
  sendingMethod,
  setSendingMethod,
  isMotoOcasion = false,
}: Readonly<RecipientSendingMethodProps>) {
  const { companyInfo } = useContext(userInfoContext);
  const isAccount = companyInfo.representative?.dni === recipient.dni;
  const handleChange = (event: ChangeEvent<HTMLSelectElement>) => {
    setSendingMethod(event.target.value as SendingMethod);
  };

  return (
    <select
      defaultValue={sendingMethod}
      onChange={handleChange}
      className="border-0 py-2.5 font-semibold text-blue-800 rounded-lg hover:bg-blue-50"
    >
      <option value="" disabled>
        Seleccionar:
      </option>
      {recipient.email && (
        <option value={SendingMethod.EMAIL}>Correo electrónico</option>
      )}
      {!isMotoOcasion && recipient.phone && (
        <option value={SendingMethod.WHATSAPP}>WhatsApp</option>
      )}
      {isMotoOcasion && isAccount && (
        <option value={SendingMethod.CERTIFICATE}>
          Certificado electrónico
        </option>
      )}
    </select>
  );
}
